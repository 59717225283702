import { EditorOptions, useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';
import Paragraph from '@tiptap/extension-paragraph';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import Link from '@tiptap/extension-link';
import CharacterCount, {
	CharacterCountOptions,
} from '@tiptap/extension-character-count';
import History from '@tiptap/extension-history';

export type UseRichTextEditorProps = Omit<
Partial<EditorOptions>,
'extensions'
> &
Pick<CharacterCountOptions, 'limit'>;

const useRichTextEditor = ( props?: UseRichTextEditorProps ) => {
	const { limit, ...spreadableProps } = props || {};
	const editor = useEditor(
		{
			extensions: [
				Document,
				Paragraph,
				Text,
				Bold,
				Underline,
				Italic,
				OrderedList,
				ListItem,
				BulletList,
				Link,
				CharacterCount.configure( { limit } ),
				History,
			],
			...spreadableProps,
		},
		[]
	);
	return editor;
};

export default useRichTextEditor;
