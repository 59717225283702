import { Box, Divider, List, Typography } from '@mui/material';
import React from 'react';
import {
	InquiriesConfigurationFormStandardField,
	itemIsStandardField,
	InquiriesFormSimpleItem,
} from './InquiriesConfigurationFormFields';
import styles from './InquiriesConfigurationForm.module.scss';
import classNames from 'classnames';
import {
	InquiriesConfigurationFormServicesField,
	itemIsServicesField,
} from './InquiriesConfigurationFormFields/InquiriesConfigurationFormServicesField';
import MuiTipTap from '../../../mui/MuiTipTap';
import { useInquiriesConfigurationFields } from './useInquiriesConfigurationFields';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

export const InquiriesConfigurationForm: React.FC = () => {
	const {
		availableItems,
		handleToggle,
		instructions,
		setInstructions,
		usedItems,
		onDragEnd,
	} = useInquiriesConfigurationFields();

	return (
		<Box className={ styles.container }>
			<Box className={ styles.column }>
				<Typography variant='h6'>Available fields</Typography>
				<List className={ styles.list } disablePadding>
					<InquiriesFormSimpleItem
						id='customText'
						type='Instructions'
						forceRequired
						included
						onToggle={ handleToggle }
					/>
					{ availableItems.map( ( item ) => (
						<InquiriesFormSimpleItem
							key={ item.id }
							{ ...item }
							onToggle={ handleToggle }
						/>
					) ) }
				</List>
			</Box>
			<Divider orientation='vertical' />
			<Box className={ styles.column }>
				<Typography variant='h6'>Layout</Typography>
				<MuiTipTap
					fullWidth
					label='Instructions'
					value={ instructions }
					onChange={ setInstructions }
					countCharacters
					maxLength={ 1000 }
				/>
				<DragDropContext onDragEnd={ onDragEnd }>
					<Droppable droppableId='configuration'>
						{ ( provided ) => (
							<List
								disablePadding
								className={ classNames( styles.list, styles.large ) }
								{ ...provided.droppableProps }
								ref={ provided.innerRef }
							>
								{ usedItems.map( ( item, index ) =>
									itemIsStandardField( item ) ? (
										<InquiriesConfigurationFormStandardField
											key={ item.id }
											{ ...item }
											index={ index }
										/>
									) : itemIsServicesField( item ) ? (
										<InquiriesConfigurationFormServicesField
											key={ item.id }
											{ ...item }
											index={ index }
										/>
									) : null
								) }
								{ provided.placeholder }
							</List>
						) }
					</Droppable>
				</DragDropContext>
			</Box>
		</Box>
	);
};
