import Query from '../../Query.js';
import { flattenStripeMetadata } from '../../helpers';
import {
	OrgDetailReturnFields,
	OrgReturnFields,
	OrganizationScalarfields,
	OrgUserWithClientsReturnFields,
	StripeConnectAccountReturnFields,
	TransactionHistoryItemsReturnFields,
} from '../returnFields';

/**
 * Create a unique Organization object. Only required fields are listed here.
 *
 * @param {object} data - "input OrganizationCreateInput"
 * @param {string} data.name - Org name.
 * @param {string} data.name - Org name.
 * @param {string} data.contactEmail - Org email.
 * @param {string} data.phone - Org phone.
 * @param {string} data.addressLine1 - Org street address.
 * @param {string} data.city - Org city.
 * @param {string} data.state - Org state.
 * @param {string} data.country - Org country.
 * @param {string} data.postalCode - Org postal code.
 *
 * @returns {object} res - {org, errors}
 */
const createOrganization = async function( data ) {
	const res = await this.request(
		new Query( {
			type: 'mutation',
			name: 'createOrganization',
			params: { data },
			returnFields: OrgReturnFields,
		} )
	);

	if ( !res.errors ) {
		return {
			org: res.data.data.createOrganization,
			errors: res.data.data.errors,
		};
	}
	return { errors: res.errors };
};

/**
 * Update a unique Organization object. Only required fields are listed here.
 *
 * @param {object} data - "input OrganizationCreateInput"
 * @param {string} data.name - Org name.
 * @param {string} data.name - Org name.
 * @param {string} data.contactEmail - Org email.
 * @param {string} data.phone - Org phone.
 * @param {string} data.addressLine1 - Org street address.
 * @param {string} data.city - Org city.
 * @param {string} data.state - Org state.
 * @param {string} data.country - Org country.
 * @param {string} data.postalCode - Org postal code.
 *
 * @returns {object} res - {org, errors}
 */
const updateOrganization = async function( data, id ) {
	const where = { id };

	const res = await this.request(
		new Query( {
			type: 'mutation',
			name: 'updateOrganization',
			params: { data, where },
			returnFields: OrgDetailReturnFields,
		} )
	);

	if ( !res.errors ) {
		return {
			org: res.data.data.updateOrganization,
			errors: res.data.errors,
		};
	}

	return { errors: res.errors };
};

/** @typedef {{ id: string, image: string, updatedAt: string | Date, user: { id: string, email: string, blocked?: boolean }, organization: { id: string }, contacts?: { id: string }[] } } OrganizationUser */
/** @typedef {{ totalUsers: number, defaultOrgUser: { id: string, firstName: string, lastName: string }, users: Array<OrganizationUser>, adminUsers: Array<OrganizationUser>, disabledMembers: Array<OrganizationUser> }} OrganizationUsers */
/**
 * Get the users and admin users of an organization.
 *
 * @param { string } id - The Org's id.
 * @param { Array< Record<string, any> > | Record<string, any> | null } [orderBy]
 *
 * @returns { Promise< OrganizationUsers | { errors: Array<Error> } }
 */
const getOrganizationUsers = async function( id, orderBy ) {
	let orderByParam = orderBy || { firstName: 'desc' };

	if ( !Array.isArray( orderByParam ) ) {
		orderByParam = [ orderByParam ];
	}

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationWhere',
			params: {
				where: { id },
			},
			returnFields: [
				{
					defaultOrgUser: [
						'id',
						'firstName',
						'lastName',
						{
							user: [ 'id' ],
						},
					],
				},
				'_usersCount',
				...OrganizationScalarfields,
				new Query( {
					name: 'users',
					params: {
						orderBy: orderByParam,
					},
					returnFields: OrgUserWithClientsReturnFields,
				} ),
				new Query( {
					name: 'adminUsers',
					params: {
						orderBy: orderByParam,
					},
					returnFields: OrgUserWithClientsReturnFields,
				} ),
				new Query( {
					name: 'disabledMembers',
					params: {
						orderBy: orderByParam,
					},
					returnFields: OrgUserWithClientsReturnFields,
				} ),
			],
		} )
	);

	if ( errors || data.errors ) return { errors: errors || data.errors };
	const { _usersCount, ...restOfResponse } = data.data.getOrganizationWhere;
	return {
		totalUsers: _usersCount,
		...restOfResponse,
	};
};

/**
 * Gets five payouts and all of their associated transactions for an organization.
 * Optionally retrieves payouts starting after or ending before a specified payout.
 *
 * @param {String} id - Id of the org to get the charges of.
 * @param {String} [startingAfter] - Payout Id used as a param for: https://stripe.com/docs/api/payouts/list
 *
 * @returns {Promise<{transactionHistory} | {errors}>}
 */
const getOrgTransactionHistory = async function( id, startingAfter ) {
	const params = { where: { id } };
	if ( startingAfter ) {
		params.after = startingAfter;
	}
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrgTransactionHistory',
			params: params,
			returnFields: [ { transactionHistoryItems: TransactionHistoryItemsReturnFields }, 'hasMore', ],
		} )
	);

	if ( errors ) {
		return { errors };
	}

	const transactionHistory = data.data.getOrgTransactionHistory;
	return { transactionHistory };
};

/**
 * Get the connect account for an organization.
 *
 * @param {String} id - The organization's id.
 *
 * @returns {Promise<{ errors: Error[] } | { connectAccount: Record<string, any> }>} res - { errors, connectAccount}
 */
const getStripeConnectAccount = async function( id ) {
	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getStripeConnectAccount',
			params: { where: { id: id } },
			returnFields: StripeConnectAccountReturnFields,
		} )
	);

	if ( errors ) {
		return { errors: errors };
	}

	const connectAccount =
		!!data?.data?.getStripeConnectAccount &&
		flattenStripeMetadata( data.data.getStripeConnectAccount );

	if ( connectAccount && connectAccount.external_accounts.data[ 0 ] ) {
		connectAccount.external_accounts.data[ 0 ] = flattenStripeMetadata(
			connectAccount.external_accounts.data[ 0 ]
		);
	}

	return { connectAccount };
};

/**
 * Gets an organization's contact information.
 *
 * @param {string} id - The Organization's ID.
 *
 * @returns {Promise<
 * {errors}
 * |
 * {errors, contactInfo: {addressLine1, addressLine2, postalCode, city, country, phone, contactEmail}
 * >} }
 */
const getOrgContactInfo = async function( id ) {
	const { errors, data } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationWhere',
			params: { where: { id } },
			returnFields: [
				'id',
				'addressLine1',
				'addressLine2',
				'postalCode',
				'city',
				'state',
				'country',
				'phone',
				'contactEmail',
			],
		} )
	);
	if ( errors ) return { errors };

	return {
		contactInfo: data.data.getOrganizationWhere,
		errors: data.data.errors,
	};
};

/**
 * Get an Organization.
 *
 * @param {Object} where - Fields to search by.
 * @param {String[]} additionalFields - Additional fields to return from the back-end.
 *
 * @returns {Object} res - { errors, org }
 */
const getOrganizationWhere = async function( where, additionalFields = [] ) {
	const res = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationWhere',
			params: { where },
			returnFields: OrgDetailReturnFields.concat( additionalFields ),
		} )
	);

	if ( !res.errors ) {
		return {
			org: res.data.data.getOrganizationWhere,
			errors: res.data.errors,
		};
	}

	return { errors: res.errors };
};

/**
 * Gets the organization associated with a given invitation.
 *
 * @param {string} invitationID - ID of invitation to get the org of.
 *
 * @returns {object} res - {errors, org}
 */
const getOrganizationFromInvitation = async function( invitationID ) {
	const where = { id: invitationID };

	const { data, errors } = await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationFromInvitation',
			params: { where },
			returnFields: OrgReturnFields,
		} )
	);

	if ( errors ) return { errors };

	const organization = data.data.getOrganizationFromInvitation;

	if ( !organization ) {
		return { errors: [ { message: 'No organization found.' } ] };
	}

	return { organization };
};

const getOrganizations = async function( params ) {
	return await this.request(
		new Query( {
			type: 'query',
			name: 'getOrganizationsWhere',
			params,
			returnFields: [ { organizations: OrgReturnFields } ],
		} )
	);
};

/**
 * Search for org users with an array of strings.
 *
 * @param { {
 *   queries: Array<string>,
 *   id: string,
 *   limit?: number,
 *   skip?: number
 * } } arg
 * @returns {Promise<Object[]>}
 */
const searchOrganizations = async function( { queries, id, limit, skip } ) {
	const results = [];

	const queryReducer = ( constraints, query ) => {
		return [
			...constraints,
			{ name_contains: query },
			{ city_contains: query },
			{ contactEmail_contains: query },
			{ website_contains: query },
		];
	};

	const orgWhere = {
		defaultOrgUser: {
			user: {
				onboardingCompleted: true,
			},
		},
		OR: queries.reduce( queryReducer, [] ),
	};

	// get connected organizations first.
	const { orgs, errors: getClientErrors } = await this.getClientConnectedOrgs( {
		clientID: id,
		where: orgWhere,
		limit,
		skip,
	} );

	if ( !getClientErrors ) {
		const IDs = [];

		if ( orgs.length ) {
			orgs.forEach( ( org ) => {
				IDs.push( org.id );
				org.contact = org.contacts[ 0 ];
				results.push( org );
			} );
		}

		if ( results.length >= limit ) return results;

		// before next request, preclude previous results from being duplicated
		orgWhere.AND = {
			NOT: IDs.reduce( ( acc, id ) => [ ...acc, { id } ], [] ),
			...orgWhere.AND,
		};

		// get unconnected clients next, so vendors/planner can seek out new clients
		const { data, errors: getOrgErrors } = await this.request(
			new Query( {
				type: 'query',
				name: 'getOrganizationsWhere',
				params: {
					where: orgWhere,
					take: limit - results.length, // only get as many as we need
					skip,
				},
				returnFields: [
					{
						organizations: [
							...OrgReturnFields,
							'state',
							'city'
						],
					},
				],
			} )
		);

		if ( !getOrgErrors ) {
			const orgs = data.data.getOrganizationsWhere.organizations;
			orgs.forEach( ( org ) => results.push( org ) );
		}
	}

	return results;
};

const disableOrgMember = async function( id, orderByParam ) {
	const res = await this.request(
		new Query( {
			type: 'mutation',
			name: 'disableOrgMember',
			params: { where: { id } },
			returnFields: [
				{ defaultOrgUser: [ 'id' ] },
				...OrganizationScalarfields,
				new Query( {
					name: 'users',
					params: {
						orderBy: orderByParam,
					},
					returnFields: OrgUserWithClientsReturnFields,
				} ),
				new Query( {
					name: 'adminUsers',
					params: {
						orderBy: orderByParam,
					},
					returnFields: OrgUserWithClientsReturnFields,
				} ),
				new Query( {
					name: 'disabledMembers',
					params: {
						orderBy: orderByParam,
					},
					returnFields: OrgUserWithClientsReturnFields,
				} ),
			],
		} )
	);

	if ( res.data && res.data.data && res.data.data.disableOrgMember ) {
		return { organization: res.data.data.disableOrgMember };
	}

	if ( res.errors ) {
		return { errors: res.errors };
	}
};

const reactivateOrgMember = async function( id, orderByParam ) {
	const res = await this.request(
		new Query( {
			type: 'mutation',
			name: 'reactivateOrgMember',
			params: { where: { id } },
			returnFields: [
				{ defaultOrgUser: [ 'id' ] },
				...OrganizationScalarfields,
				new Query( {
					name: 'users',
					params: {
						orderBy: orderByParam,
					},
					returnFields: OrgUserWithClientsReturnFields,
				} ),
				new Query( {
					name: 'adminUsers',
					params: {
						orderBy: orderByParam,
					},
					returnFields: OrgUserWithClientsReturnFields,
				} ),
				new Query( {
					name: 'disabledMembers',
					params: {
						orderBy: orderByParam,
					},
					returnFields: OrgUserWithClientsReturnFields,
				} ),
			],
		} )
	);

	if ( res.data && res.data.data && res.data.data.reactivateOrgMember ) {
		return { organization: res.data.data.reactivateOrgMember };
	}

	if ( res.errors ) {
		return { errors: res.errors };
	}
};

const getConnectOnboardingUrl = async function( { return_url, type } ) {
	const res = await this.request(
		new Query( {
			type: 'query',
			name: 'getConnectOnboardingUrl',
			params: {
				data: {
					type,
					return_url,
				},
			},
			returnFields: [ 'url' ],
		} )
	);
	if ( res.data && res.data.data && res.data.data.getConnectOnboardingUrl ) {
		return { url: res.data.data.getConnectOnboardingUrl.url };
	}
};

export {
	createOrganization,
	disableOrgMember,
	reactivateOrgMember,
	getConnectOnboardingUrl,
	getOrganizationFromInvitation,
	getOrganizations,
	getOrganizationUsers,
	getOrganizationWhere,
	getOrgContactInfo,
	getOrgTransactionHistory,
	getStripeConnectAccount,
	searchOrganizations,
	updateOrganization,
};
