import { useCallback, useState } from 'react';
import { Columns, CommonFieldData } from './types';
import rpcShared, { RPCShared } from '@rockpapercoin/rpc-shared';
import { OnDragEndResponder } from '@hello-pangea/dnd';

type Field = RPCShared.Inquiries.Field;
type FieldId = Field['id'];

const getDefaultLabelById = ( id: FieldId, type: string ) => {
	if ( id === 'firstNameTwo' ) {
		return 'Second first name';
	} else if ( id === 'lastNameTwo' ) {
		return 'Second last name';
	} else if ( id === 'guestCount' ) {
		return 'How many people will be at your event?';
	} else if ( id === 'budget' ) {
		return 'What is your total estimated budget?';
	} else if ( id === 'services' ) {
		return 'What services are you interested in?';
	} else if ( id === 'customText' ) {
		return 'Additional details';
	} else {
		return type;
	}
};

const getColumnLabelById = ( id: FieldId ) => {
	if ( [
		'firstNameOne',
		'firstNameTwo',
		'email'
	].includes( id ) ) {
		return Columns.Left;
	} else if ( [
		'lastNameOne',
		'lastNameTwo',
		'phone'
	].includes( id ) ) {
		return Columns.Right;
	} else {
		return Columns.Both;
	}
};

const supplementWithDefaultData = ( items: Field[] ) =>
	items.map(
		( item ) =>
			( {
				...item,
				label: getDefaultLabelById( item.id, item.type ),
				required: !!item.forceRequired || item.id !== 'customText',
				column: getColumnLabelById( item.id ),
				included: !!item.forceRequired,
			} as CommonFieldData<typeof item.id> )
	);

const supplementedItems = supplementWithDefaultData( rpcShared.inquiries.fields );

const lastNameTwo = supplementedItems.find(
	( field ) => field.id === 'lastNameTwo'
);
const mutatedListForPairedItems = supplementedItems
	.filter( ( field ) => field.id !== 'lastNameTwo' )
	.map( ( field ) =>
		field.id === 'firstNameTwo'
			? { ...field, type: 'Additional name', lastNameTwo }
			: field
	);

const allItems = supplementWithDefaultData( mutatedListForPairedItems );

const initialItems = allItems.filter( ( field ) => field.forceRequired );

export const useInquiriesConfigurationFields = () => {
	const [ availableItems, setAvailableItems ] = useState( allItems );
	const [ usedItems, setUsedItems ] = useState( initialItems );
	const [ instructions, setInstructions ] = useState( 'Contact us' );

	const setItem = useCallback(
		<
			P extends FieldId,
			T extends keyof Pick<
			CommonFieldData<P>,
			'required' | 'label' | 'placeholder' | 'multiple'
			>,
			K extends CommonFieldData<P>[T]
		>(
			id: string,
			field: T,
			value: K
		) => {
			setUsedItems( ( prevState ) => {
				const newState = [ ...prevState ];
				const item = newState.find( ( item ) => item.id === id );
				if ( item ) {
					item[ field ] = value;
					return newState;
				} else {
					return prevState;
				}
			} );
		},
		[]
	);

	const handleToggle = useCallback(
		<T extends FieldId>( id: CommonFieldData<T>['id'], value: boolean ) => {
			setAvailableItems( ( prevState ) => {
				const newState = [ ...prevState ];
				const item = newState.find( ( item ) => item.id === id );
				if ( item ) {
					item.included = value;
					if ( value ) {
						setUsedItems( ( prevState ) => [ ...prevState, item ] );
					} else {
						setUsedItems( ( prevState ) =>
							prevState.filter( ( item ) => item.id !== id )
						);
					}
					return newState;
				} else {
					return prevState;
				}
			} );
		},
		[]
	);

	const stateMutations = useCallback(
		( id: string ) => ( {
			setRequired: ( value: boolean ) => setItem( id, 'required', value ),
			setLabel: ( value: string ) => setItem( id, 'label', value ),
			setPlaceholder: ( value?: string ) => setItem( id, 'placeholder', value ),
			setMultiple: ( value?: boolean ) => setItem( id, 'multiple', value ),
		} ),
		[ setItem ]
	);

	const reorder = useCallback(
		<T>( items: T[], sourceIndex: number, destinationIndex: number ): T[] => {
			// using Array.from here to cover instances when the "list" is coming out of Apollo as readonly
			const mutableItems = Array.from( items );
			const [ removed ] = mutableItems.splice( sourceIndex, 1 );
			mutableItems.splice( destinationIndex, 0, removed );
			mutableItems.forEach( ( item, index ) => ( { ...item, index } ) );
			return mutableItems;
		},
		[]
	);

	const onDragEnd: OnDragEndResponder = useCallback(
		( result ) => {
			const { reason, destination, source } = result;
			if ( reason === 'DROP' && destination ) {
				if ( source.droppableId === destination.droppableId ) {
					// we're reordering within the same list
					setUsedItems( ( prevState ) =>
						reorder( prevState, source.index, destination.index )
					);
				} else {
					/* Being in here means we're dragging from one list to another
					but that's not supported here, so we do nothing */
				}
			}
		},
		[ reorder ]
	);

	return {
		availableItems,
		usedItems: usedItems.map( ( item ) => ( {
			...item,
			...stateMutations( item.id ),
		} ) ),
		setUsedItems,
		instructions,
		setInstructions,
		handleToggle,
		onDragEnd,
	};
};
