/* eslint max-len: 0 */
const ChevronUp = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M11.468 8.234c.275-.283.71-.31 1.014-.08l.088.078 6.204 6.325c.302.309.301.807-.002 1.114-.276.28-.707.304-1.01.074l-.087-.076-5.653-5.764-5.695 5.86c-.273.282-.704.31-1.009.084l-.087-.076c-.277-.278-.305-.715-.082-1.025l.075-.089 6.244-6.425z'
			transform='translate(-464 -1020) translate(144 183) translate(0 783) translate(0 53) translate(320 1)'
		/>
	</svg>
);

export default ChevronUp;
