import React from 'react';
import Link from 'next/link';
import styles from './SideNavLinkItem.module.scss';
import SideNavButtonItem from '../SideNavButtonItem/SideNavButtonItem';
import { CustomMenuLinkItemType } from '../../CustomMenuItem/CustomMenuItemComponent';

export default function SideNavLinkItem( {
	menuItem,
}: {
	menuItem: CustomMenuLinkItemType;
} ) {
	return (
		<Link href={ menuItem.href } className={ styles.linkWrap } passHref>
			<SideNavButtonItem menuItem={ menuItem } />
		</Link>
	);
}
