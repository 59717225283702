import React from 'react';
import { useRouter } from 'next/router';

import SideNavLinkItem from './SideNavLinkItem/SideNavLinkItem';
import SideNavDropdownItem from './SideNavDropdownItem/SideNavDropdownItem';
import styles from './SideNav.module.scss';
import { MenuConfigType } from './SideNavContainer';

export default function SideNavComponent( {
	menuConfig,
}: {
	menuConfig: MenuConfigType;
} ) {
	const router = useRouter();

	return (
		<div className={ styles.root } data-cy={ 'sideNav' }>
			{ menuConfig.map( ( menuItem ) => {
				if ( menuItem.isVisible !== undefined && !menuItem.isVisible ) return;
				if ( menuItem.isDisabled !== undefined && menuItem.isDisabled ) return;

				if ( 'items' in menuItem ) {
					return <SideNavDropdownItem menuItem={ menuItem } key={ menuItem.id } />;
				} else if ( 'href' in menuItem && menuItem.href ) {
					return (
						<SideNavLinkItem
							key={ menuItem.id }
							menuItem={ {
								isActive: router.asPath === menuItem.href,
								...menuItem,
							} }
						/>
					);
				} else {
					return null;
				}
			} ) }
		</div>
	);
}
