/* eslint max-len: 0 */
const BellOff = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M14.216 21.03c.398.117.625.534.509.931C14.37 23.17 13.262 24 12.002 24c-1.259 0-2.367-.83-2.722-2.038-.116-.397.111-.814.509-.93.364-.108.745.075.895.412l.036.096c.167.57.69.96 1.282.96.548 0 1.035-.333 1.239-.832l.044-.128c.117-.398.534-.625.931-.509zM12 0c.38 0 .693.282.743.648l.007.102v1.534c4.205.379 7.5 3.912 7.5 8.216 0 3.011.272 5.144.715 6.54.145.459.295.79.435 1.012.062.098.089.129.07.113.552.444.238 1.335-.47 1.335H3c-.625 0-.976-.72-.59-1.212l.034-.048c.133-.197.353-.66.587-1.452.447-1.516.719-3.586.719-6.288l.004-.252c.126-4.189 3.374-7.593 7.496-7.964V.75c0-.414.336-.75.75-.75zm0 3.75c-3.728 0-6.75 3.022-6.75 6.75 0 2.84-.29 5.052-.78 6.713-.072.24-.143.46-.215.658L4.206 18h15.506l-.102-.279-.075-.227c-.494-1.558-.785-3.84-.785-6.994 0-3.728-3.022-6.75-6.75-6.75z'
			transform='translate(-264 -1260) translate(144 183) translate(0 783) translate(0 53) translate(0 241) translate(120)'
		/>
	</svg>
);

export default BellOff;
