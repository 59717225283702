// Unchanging global variables.
// Variables likely to change during app use belong in redux.
const Globals = {};

// path names of routes which are part of on-boarding for various user types
Globals.onBoardingRoutes = [
	'/SignUp',
	'/orgOnboarding',
	'/orgOnboarding/[step]',
	'/ClientSignUp',
	'/ClientSignUp/[page]',
	'/OrgMemberSignUp',
	'/OrgMemberSignUp/[page]',
	'/invitation',
	'/invitation/[invitationID]',
];

// path names of routes which anonymous users are allowed to interact with
Globals.anonymousRoutes = [
	'/Login',
	'/ResetPassword',
	'/invoice/[id]',
	'/contract/[id]',
	'/proposal/[id]',
	'/invoice/oauth',
	...Globals.onBoardingRoutes,
];

Globals.superAdminRoutes = [
	'/ClientUsers',
	'/OrgUsers',
	'/Organizations'
];

Globals.itemReturnLimit = 10;

Globals.nonDismissableEvents = [
	'SubscriptionPastDue',
	'ClientFinishedOnBoardingWithoutPaymentMethod',
	'PayoutFailed',
];

Globals.acceptedContractFileTypes = [
	'image/jpeg',
	'image/png',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/pdf',
	'application/msword',
];

Globals.acceptedInvoiceAttachmentFileTypes = [
	'image/jpeg',
	'image/png',
	'text/csv',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'image/gif',
	'application/pdf',
	'application/msword',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

Globals.acceptedSharedDocumentFileTypes = [
	'video/x-msvideo',
	'image/bmp',
	'text/csv',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'image/gif',
	'image/jpeg',
	'audio/mpeg',
	'video/mp4',
	'video/mpeg',
	'application/vnd.oasis.opendocument.presentation',
	'image/png',
	'application/pdf',
	'application/vnd.ms-powerpoint',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'image/svg+xml',
	'image/tiff',
	'video/webm',
	'application/vnd.ms-excel',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

Globals.googleFileMimeTypes = [
	'application/vnd.google-apps.spreadsheet',
	'application/vnd.google-apps.document',
	'application/vnd.google-apps.presentation',
];

Globals.rootPortalId = 'rootPortal';

Globals.betaYearExpirationDate = '2022-07-01';

Globals.trialPeriodDays = 30;

Globals.DocumentBundle = 'documentBundleForContact';

Globals.customerIsOrguserErrorText = 'This customer is an orgUser.';

Globals.maxAttachmentSize = 10000000;

export default Globals;
