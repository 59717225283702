import { IconButton, ListItem, Typography } from '@mui/material';
import { RPCShared } from '@rockpapercoin/rpc-shared';
import React from 'react';
import Icon from '../../../../../elements/icons';
import styles from './InquiriesFormSimpleItem.module.scss';
import classNames from 'classnames';
import { CommonFieldData } from '../../types';

type Field = RPCShared.Inquiries.Field;

export type InquiriesFormSimpleItemProps = Pick<
CommonFieldData<Field['id']>,
'id' | 'forceRequired' | 'type'
> & {
	included: boolean;
	onToggle: ( id: Field['id'], value: boolean ) => void;
};

export const InquiriesFormSimpleItem = ( {
	id,
	type,
	included,
	forceRequired,
	onToggle,
}: InquiriesFormSimpleItemProps ) => (
	<ListItem
		className={ styles.container }
		secondaryAction={
			forceRequired ? undefined : (
				<IconButton onClick={ () => onToggle( id, !included ) }>
					<Icon
						type={ included ? 'view' : 'hide' }
						className={ classNames(
							styles.icon,
							included ? styles.view : styles.hide
						) }
					/>
				</IconButton>
			)
		}
	>
		<Typography component='span'>{ type }</Typography>
	</ListItem>
);
