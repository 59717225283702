import * as Types from '../../../../types/generated';

import { gql } from '@apollo/client';
import { GetAuthedUserFlattenedUserFieldsCalculatedOrgUserFragmentDoc } from '../../../../types/user/__generated__/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrgOnboarding_GetCouponDetailsQueryVariables = Types.Exact<{
  where: Types.CouponByNameInput;
}>;


export type OrgOnboarding_GetCouponDetailsQuery = { __typename?: 'Query', getCouponDetails?: { __typename?: 'ReducedCoupon', id: string, active: boolean, percent_off: number, duration_in_months?: number | null } | null };

export type OrgOnboarding_RegisterCouponUsageMutationVariables = Types.Exact<{
  data: Types.RegisterCouponUsageWhere;
  where: Types.OrganizationWhereUniqueInput;
}>;


export type OrgOnboarding_RegisterCouponUsageMutation = { __typename?: 'Mutation', registerCouponUsage: { __typename?: 'Coupon', id: string } };

export type OrgOnboarding_UpdateOrgUserExposureMutationVariables = Types.Exact<{
  data: Types.OrgUserUpdateCustomInput;
  where: Types.OrgUserWhereUniqueInput;
}>;


export type OrgOnboarding_UpdateOrgUserExposureMutation = { __typename?: 'Mutation', updateOrgUser?: { __typename?: 'OrgUser', id: string } | null };

export type OrgOnboarding_MarkUserOnboardingCompleteMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type OrgOnboarding_MarkUserOnboardingCompleteMutation = { __typename?: 'Mutation', markUserOnboardingComplete: boolean };

export type OrgOnboarding_LoginQueryVariables = Types.Exact<{
  data: Types.UserLoginInput;
}>;


export type OrgOnboarding_LoginQuery = { __typename?: 'Query', login?: { __typename?: 'AuthPayload', token: string } | null };

export type OrgOnboarding_GetAuthedUserQueryVariables = Types.Exact<{
  where: Types.UserWhereUniqueInput;
}>;


export type OrgOnboarding_GetAuthedUserQuery = { __typename?: 'Query', getAuthedUser?: { __typename?: 'AuthedUser', userType: string, orgUser?: { __typename?: 'CalculatedOrgUser', id: string, phone?: string | null, isAdmin: boolean, isOwner: boolean, firstName: string, lastName: string, image?: string | null, updatedAt: Date | string, user: { __typename?: 'User', id: string, userType: Types.UserType, email: string, notificationPreference?: Types.NotificationsPreference | null }, organization?: { __typename?: 'Organization', id: string, name: string, website?: string | null, phone?: string | null, contactEmail?: string | null, description?: string | null, image?: string | null, brandedEmails?: boolean | null, automatedRemindersEnabled: boolean, invoicesAllowGratuity: boolean, updatedAt: Date | string, facebook?: string | null, instagram?: string | null, twitter?: string | null, pinterest?: string | null, groups: Array<{ __typename?: 'TestingGroup', id: string, name: string }>, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null } | null } | null, groups: Array<{ __typename?: 'TestingGroup', id: string, name: string }> } | null };


export const OrgOnboarding_GetCouponDetailsDocument = gql`
    query OrgOnboarding_getCouponDetails($where: CouponByNameInput!) {
  getCouponDetails(where: $where) {
    id
    active
    percent_off
    duration_in_months
  }
}
    `;

/**
 * __useOrgOnboarding_GetCouponDetailsQuery__
 *
 * To run a query within a React component, call `useOrgOnboarding_GetCouponDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_GetCouponDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboarding_GetCouponDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_GetCouponDetailsQuery(baseOptions: Apollo.QueryHookOptions<OrgOnboarding_GetCouponDetailsQuery, OrgOnboarding_GetCouponDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboarding_GetCouponDetailsQuery, OrgOnboarding_GetCouponDetailsQueryVariables>(OrgOnboarding_GetCouponDetailsDocument, options);
      }
export function useOrgOnboarding_GetCouponDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboarding_GetCouponDetailsQuery, OrgOnboarding_GetCouponDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboarding_GetCouponDetailsQuery, OrgOnboarding_GetCouponDetailsQueryVariables>(OrgOnboarding_GetCouponDetailsDocument, options);
        }
export type OrgOnboarding_GetCouponDetailsQueryHookResult = ReturnType<typeof useOrgOnboarding_GetCouponDetailsQuery>;
export type OrgOnboarding_GetCouponDetailsLazyQueryHookResult = ReturnType<typeof useOrgOnboarding_GetCouponDetailsLazyQuery>;
export type OrgOnboarding_GetCouponDetailsQueryResult = Apollo.QueryResult<OrgOnboarding_GetCouponDetailsQuery, OrgOnboarding_GetCouponDetailsQueryVariables>;
export const OrgOnboarding_RegisterCouponUsageDocument = gql`
    mutation OrgOnboarding_registerCouponUsage($data: RegisterCouponUsageWhere!, $where: OrganizationWhereUniqueInput!) {
  registerCouponUsage(data: $data, where: $where) {
    id
  }
}
    `;
export type OrgOnboarding_RegisterCouponUsageMutationFn = Apollo.MutationFunction<OrgOnboarding_RegisterCouponUsageMutation, OrgOnboarding_RegisterCouponUsageMutationVariables>;

/**
 * __useOrgOnboarding_RegisterCouponUsageMutation__
 *
 * To run a mutation, you first call `useOrgOnboarding_RegisterCouponUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_RegisterCouponUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingRegisterCouponUsageMutation, { data, loading, error }] = useOrgOnboarding_RegisterCouponUsageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_RegisterCouponUsageMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboarding_RegisterCouponUsageMutation, OrgOnboarding_RegisterCouponUsageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboarding_RegisterCouponUsageMutation, OrgOnboarding_RegisterCouponUsageMutationVariables>(OrgOnboarding_RegisterCouponUsageDocument, options);
      }
export type OrgOnboarding_RegisterCouponUsageMutationHookResult = ReturnType<typeof useOrgOnboarding_RegisterCouponUsageMutation>;
export type OrgOnboarding_RegisterCouponUsageMutationResult = Apollo.MutationResult<OrgOnboarding_RegisterCouponUsageMutation>;
export type OrgOnboarding_RegisterCouponUsageMutationOptions = Apollo.BaseMutationOptions<OrgOnboarding_RegisterCouponUsageMutation, OrgOnboarding_RegisterCouponUsageMutationVariables>;
export const OrgOnboarding_UpdateOrgUserExposureDocument = gql`
    mutation OrgOnboarding_updateOrgUserExposure($data: OrgUserUpdateCustomInput!, $where: OrgUserWhereUniqueInput!) {
  updateOrgUser(data: $data, where: $where) {
    id
  }
}
    `;
export type OrgOnboarding_UpdateOrgUserExposureMutationFn = Apollo.MutationFunction<OrgOnboarding_UpdateOrgUserExposureMutation, OrgOnboarding_UpdateOrgUserExposureMutationVariables>;

/**
 * __useOrgOnboarding_UpdateOrgUserExposureMutation__
 *
 * To run a mutation, you first call `useOrgOnboarding_UpdateOrgUserExposureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_UpdateOrgUserExposureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingUpdateOrgUserExposureMutation, { data, loading, error }] = useOrgOnboarding_UpdateOrgUserExposureMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_UpdateOrgUserExposureMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboarding_UpdateOrgUserExposureMutation, OrgOnboarding_UpdateOrgUserExposureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboarding_UpdateOrgUserExposureMutation, OrgOnboarding_UpdateOrgUserExposureMutationVariables>(OrgOnboarding_UpdateOrgUserExposureDocument, options);
      }
export type OrgOnboarding_UpdateOrgUserExposureMutationHookResult = ReturnType<typeof useOrgOnboarding_UpdateOrgUserExposureMutation>;
export type OrgOnboarding_UpdateOrgUserExposureMutationResult = Apollo.MutationResult<OrgOnboarding_UpdateOrgUserExposureMutation>;
export type OrgOnboarding_UpdateOrgUserExposureMutationOptions = Apollo.BaseMutationOptions<OrgOnboarding_UpdateOrgUserExposureMutation, OrgOnboarding_UpdateOrgUserExposureMutationVariables>;
export const OrgOnboarding_MarkUserOnboardingCompleteDocument = gql`
    mutation OrgOnboarding_markUserOnboardingComplete {
  markUserOnboardingComplete
}
    `;
export type OrgOnboarding_MarkUserOnboardingCompleteMutationFn = Apollo.MutationFunction<OrgOnboarding_MarkUserOnboardingCompleteMutation, OrgOnboarding_MarkUserOnboardingCompleteMutationVariables>;

/**
 * __useOrgOnboarding_MarkUserOnboardingCompleteMutation__
 *
 * To run a mutation, you first call `useOrgOnboarding_MarkUserOnboardingCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_MarkUserOnboardingCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orgOnboardingMarkUserOnboardingCompleteMutation, { data, loading, error }] = useOrgOnboarding_MarkUserOnboardingCompleteMutation({
 *   variables: {
 *   },
 * });
 */
export function useOrgOnboarding_MarkUserOnboardingCompleteMutation(baseOptions?: Apollo.MutationHookOptions<OrgOnboarding_MarkUserOnboardingCompleteMutation, OrgOnboarding_MarkUserOnboardingCompleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrgOnboarding_MarkUserOnboardingCompleteMutation, OrgOnboarding_MarkUserOnboardingCompleteMutationVariables>(OrgOnboarding_MarkUserOnboardingCompleteDocument, options);
      }
export type OrgOnboarding_MarkUserOnboardingCompleteMutationHookResult = ReturnType<typeof useOrgOnboarding_MarkUserOnboardingCompleteMutation>;
export type OrgOnboarding_MarkUserOnboardingCompleteMutationResult = Apollo.MutationResult<OrgOnboarding_MarkUserOnboardingCompleteMutation>;
export type OrgOnboarding_MarkUserOnboardingCompleteMutationOptions = Apollo.BaseMutationOptions<OrgOnboarding_MarkUserOnboardingCompleteMutation, OrgOnboarding_MarkUserOnboardingCompleteMutationVariables>;
export const OrgOnboarding_LoginDocument = gql`
    query OrgOnboarding_login($data: UserLoginInput!) {
  login(data: $data) {
    token
  }
}
    `;

/**
 * __useOrgOnboarding_LoginQuery__
 *
 * To run a query within a React component, call `useOrgOnboarding_LoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_LoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboarding_LoginQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrgOnboarding_LoginQuery(baseOptions: Apollo.QueryHookOptions<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>(OrgOnboarding_LoginDocument, options);
      }
export function useOrgOnboarding_LoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>(OrgOnboarding_LoginDocument, options);
        }
export type OrgOnboarding_LoginQueryHookResult = ReturnType<typeof useOrgOnboarding_LoginQuery>;
export type OrgOnboarding_LoginLazyQueryHookResult = ReturnType<typeof useOrgOnboarding_LoginLazyQuery>;
export type OrgOnboarding_LoginQueryResult = Apollo.QueryResult<OrgOnboarding_LoginQuery, OrgOnboarding_LoginQueryVariables>;
export const OrgOnboarding_GetAuthedUserDocument = gql`
    query OrgOnboarding_getAuthedUser($where: UserWhereUniqueInput!) {
  getAuthedUser(where: $where) {
    userType
    orgUser {
      ...getAuthedUserFlattenedUserFieldsCalculatedOrgUser
    }
    groups {
      id
      name
    }
  }
}
    ${GetAuthedUserFlattenedUserFieldsCalculatedOrgUserFragmentDoc}`;

/**
 * __useOrgOnboarding_GetAuthedUserQuery__
 *
 * To run a query within a React component, call `useOrgOnboarding_GetAuthedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgOnboarding_GetAuthedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgOnboarding_GetAuthedUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrgOnboarding_GetAuthedUserQuery(baseOptions: Apollo.QueryHookOptions<OrgOnboarding_GetAuthedUserQuery, OrgOnboarding_GetAuthedUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgOnboarding_GetAuthedUserQuery, OrgOnboarding_GetAuthedUserQueryVariables>(OrgOnboarding_GetAuthedUserDocument, options);
      }
export function useOrgOnboarding_GetAuthedUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgOnboarding_GetAuthedUserQuery, OrgOnboarding_GetAuthedUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgOnboarding_GetAuthedUserQuery, OrgOnboarding_GetAuthedUserQueryVariables>(OrgOnboarding_GetAuthedUserDocument, options);
        }
export type OrgOnboarding_GetAuthedUserQueryHookResult = ReturnType<typeof useOrgOnboarding_GetAuthedUserQuery>;
export type OrgOnboarding_GetAuthedUserLazyQueryHookResult = ReturnType<typeof useOrgOnboarding_GetAuthedUserLazyQuery>;
export type OrgOnboarding_GetAuthedUserQueryResult = Apollo.QueryResult<OrgOnboarding_GetAuthedUserQuery, OrgOnboarding_GetAuthedUserQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    