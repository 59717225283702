/* eslint max-len: 0 */
const Export = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M17.25 10.5c3.728 0 6.75 3.022 6.75 6.75S20.978 24 17.25 24s-6.75-3.022-6.75-6.75 3.022-6.75 6.75-6.75zm0 1.5c-2.9 0-5.25 2.35-5.25 5.25s2.35 5.25 5.25 5.25 5.25-2.35 5.25-5.25S20.15 12 17.25 12zm0 1.5c.38 0 .693.282.743.648l.007.102v4.188l.97-.968.084-.073c.293-.218.71-.194.976.073.293.293.293.767 0 1.06l-2.25 2.25c-.024.025-.05.048-.078.069l-.029.02-.044.029-.012.006-.012.007-.01.006-.022.01-.031.014-.021.009-.023.008-.027.009-.022.006-.03.008-.025.005-.031.006-.04.004c-.014.002-.029.003-.044.003L17.25 21h-.028l-.046-.004.074.004c-.047 0-.093-.004-.137-.013l-.034-.007-.048-.012-.017-.006-.035-.012-.052-.023-.01-.005-.037-.02c-.014-.007-.028-.016-.041-.025-.042-.027-.082-.06-.12-.097l.082.071c-.029-.022-.056-.045-.081-.07l-2.25-2.25-.073-.085c-.218-.293-.194-.71.073-.976.293-.293.767-.293 1.06 0l.97.969V14.25c0-.414.336-.75.75-.75zM12.879 0c.53 0 1.04.187 1.444.525l.146.134 2.873 2.872c.374.375.603.868.65 1.392l.008.198V7.5c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648L16.5 7.5V5.121c0-.159-.05-.313-.143-.44l-.076-.09-2.872-2.872c-.113-.112-.257-.185-.412-.21l-.118-.009H2.25c-.38 0-.693.282-.743.648L1.5 2.25v16.5c0 .38.282.693.648.743l.102.007H7.5c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L7.5 21H2.25c-1.19 0-2.166-.925-2.245-2.096L0 18.75V2.25C0 1.06.925.084 2.096.005L2.25 0h10.629zM7.5 15c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L7.5 16.5H3.75c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L3.75 15H7.5zm2.25-4.5c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L9.75 12h-6c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h6zm4.5-4.5c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007H3.75c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L3.75 6h10.5z'
			transform='translate(-544 -1212) translate(144 183) translate(0 783) translate(0 53) translate(0 193) translate(400)'
		/>
	</svg>
);

export default Export;
