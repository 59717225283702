/* eslint max-len: 0 */
const CloseRegular = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M1.196.146L1.28.22 12 10.938 22.72.218c.293-.292.767-.292 1.06 0 .267.267.29.684.073.977l-.073.084L13.061 12l10.72 10.72c.292.293.292.767 0 1.06-.267.267-.684.29-.977.073l-.084-.073L12 13.06 1.28 23.78c-.293.292-.767.292-1.06 0-.267-.267-.29-.684-.073-.977l.073-.084 10.719-10.72L.219 1.279C-.072.986-.072.512.22.22c.267-.267.684-.29.977-.073z'
			transform='translate(-941 -145) translate(403 89) translate(72 56) translate(466)'
		/>
	</svg>
);

export default CloseRegular;
