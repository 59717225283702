// Packages
import React from 'react';
import { IconButton, Stack } from '@mui/material';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

// Markup
import Button from '../../../elements/Button';
import Logo from '../../../elements/Logo2.jsx';
import { HelpButton } from '../../../mui/HelpButton/HelpButton';

import useIsLoggedIn from '../../../hooks/useIsLoggedIn';
import useIsOnBoardingRoute from '../../../hooks/useIsOnBoardingRoute';
import AvatarContainer from '../../../mui/Avatar/AvatarContainer';
import { BurgerIcon, CloseIcon } from '../../../mui/Icons';
import styles from './Header.module.scss';
import { useDeviceDetection } from '../../../hooks/useDeviceDetection';

const Header = ( props ) => {
	const { toggleMobileMenu, isActiveMobileMenu } = props;
	const { isDesktop } = useDeviceDetection();
	const isOnBoardingRoute = useIsOnBoardingRoute();
	const isLoggedIn = useIsLoggedIn();
	const router = useRouter();
	const { route: currentRoute } = router;

	return (
		<header className={ styles.root }>
			<Link
				href='/'
				tabIndex='1'
				className={ styles.logo }
				data-cy='headerLogo'
				passHref
				onClick={ () => toggleMobileMenu( false ) }
			>
				<Logo />
			</Link>
			<div className={ styles.rightControls }>
				{ !isLoggedIn && currentRoute === '/Login' && (
					<Button
						className={ classNames( [ 'button--blue', styles.button ] ) }
						text='Sign Up'
						link={ { href: '/SignUp', as: '/SignUp' } }
					/>
				) }
				{ !isLoggedIn && currentRoute === '/SignUp' && !isOnBoardingRoute && (
					<Button
						className={ classNames( [ 'button--blue', styles.button ] ) }
						text='Sign In'
						link={ { href: '/Login', as: '/Login' } }
					/>
				) }

				{ isLoggedIn && !isOnBoardingRoute && (
					<>
						{ isDesktop && (
							<Stack direction='row' alignItems='center' spacing={ 1 }>
								<AvatarContainer />
								<HelpButton />
							</Stack>
						) }
						{ !isDesktop && (
							<div className={ styles.mobileToggleWrap }>
								{ isActiveMobileMenu ? (
									<IconButton
										className={ styles.mobileCloseButton }
										onClick={ () => toggleMobileMenu( false ) }
									>
										<CloseIcon />
									</IconButton>
								) : (
									<IconButton
										className={ styles.mobileOpenButton }
										onClick={ () => toggleMobileMenu( true ) }
									>
										<BurgerIcon />
									</IconButton>
								) }
							</div>
						) }
					</>
				) }
			</div>
		</header>
	);
};

export default Header;
