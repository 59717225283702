import * as Types from '../../generated';

import { gql } from '@apollo/client';
export type GetAuthedUserFlattenedUserFieldsCalculatedOrgUserFragment = { __typename?: 'CalculatedOrgUser', id: string, phone?: string | null, isAdmin: boolean, isOwner: boolean, firstName: string, lastName: string, image?: string | null, updatedAt: Date | string, user: { __typename?: 'User', id: string, userType: Types.UserType, email: string, notificationPreference?: Types.NotificationsPreference | null }, organization?: { __typename?: 'Organization', id: string, name: string, website?: string | null, phone?: string | null, contactEmail?: string | null, description?: string | null, image?: string | null, brandedEmails?: boolean | null, automatedRemindersEnabled: boolean, invoicesAllowGratuity: boolean, updatedAt: Date | string, facebook?: string | null, instagram?: string | null, twitter?: string | null, pinterest?: string | null, groups: Array<{ __typename?: 'TestingGroup', id: string, name: string }>, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null } | null };

export type GetAuthedUserFlattenedUserFieldsFragment = { __typename?: 'AuthedUser', userType: string, clientUser?: { __typename?: 'ClientUser', id: string, description?: string | null, firstNameOne: string, lastNameOne?: string | null, firstNameTwo?: string | null, lastNameTwo?: string | null, image?: string | null, updatedAt: Date | string, facebook?: string | null, instagram?: string | null, twitter?: string | null, pinterest?: string | null, weddingDate?: Date | string | null, venue?: string | null, user: { __typename?: 'User', id: string, userType: Types.UserType, email: string, notificationPreference?: Types.NotificationsPreference | null } } | null, orgUser?: { __typename?: 'CalculatedOrgUser', id: string, phone?: string | null, isAdmin: boolean, isOwner: boolean, firstName: string, lastName: string, image?: string | null, updatedAt: Date | string, user: { __typename?: 'User', id: string, userType: Types.UserType, email: string, notificationPreference?: Types.NotificationsPreference | null }, organization?: { __typename?: 'Organization', id: string, name: string, website?: string | null, phone?: string | null, contactEmail?: string | null, description?: string | null, image?: string | null, brandedEmails?: boolean | null, automatedRemindersEnabled: boolean, invoicesAllowGratuity: boolean, updatedAt: Date | string, facebook?: string | null, instagram?: string | null, twitter?: string | null, pinterest?: string | null, groups: Array<{ __typename?: 'TestingGroup', id: string, name: string }>, services?: Array<{ __typename?: 'Service', id: string, name: string }> | null } | null } | null, superAdmin?: { __typename?: 'SuperAdminUser', id: string, firstName: string, lastName: string, image?: string | null, updatedAt: Date | string, user: { __typename?: 'User', id: string, userType: Types.UserType, email: string } } | null, groups: Array<{ __typename?: 'TestingGroup', id: string, name: string }> };

export const GetAuthedUserFlattenedUserFieldsCalculatedOrgUserFragmentDoc = gql`
    fragment getAuthedUserFlattenedUserFieldsCalculatedOrgUser on CalculatedOrgUser {
  id
  phone
  isAdmin
  isOwner
  firstName
  lastName
  image
  updatedAt
  user {
    id
    userType
    email
    notificationPreference
  }
  organization {
    id
    name
    website
    phone
    contactEmail
    description
    image
    brandedEmails
    automatedRemindersEnabled
    invoicesAllowGratuity
    updatedAt
    facebook
    instagram
    twitter
    pinterest
    groups {
      id
      name
    }
    services {
      id
      name
    }
  }
}
    `;
export const GetAuthedUserFlattenedUserFieldsFragmentDoc = gql`
    fragment getAuthedUserFlattenedUserFields on AuthedUser {
  userType
  clientUser {
    id
    description
    firstNameOne
    lastNameOne
    firstNameTwo
    lastNameTwo
    image
    updatedAt
    facebook
    instagram
    twitter
    pinterest
    weddingDate
    venue
    user {
      id
      userType
      email
      notificationPreference
    }
  }
  orgUser {
    ...getAuthedUserFlattenedUserFieldsCalculatedOrgUser
  }
  superAdmin {
    id
    firstName
    lastName
    image
    updatedAt
    user {
      id
      userType
      email
    }
  }
  groups {
    id
    name
  }
}
    ${GetAuthedUserFlattenedUserFieldsCalculatedOrgUserFragmentDoc}`;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "InvoiceOrRedirect": [
      "Invoice",
      "RedirectUrl"
    ],
    "InvoiceOrStripeUser": [
      "Invoice",
      "StripeUser"
    ],
    "SourceOrRedirect": [
      "RedirectUrl",
      "Source"
    ],
    "SubscriptionObjectOrRedirect": [
      "RedirectUrl",
      "SubscriptionObject"
    ]
  }
};
      export default result;
    