import React, { useId } from 'react';
import { RPCShared } from '@rockpapercoin/rpc-shared';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectProps,
} from '@mui/material';

type Styles = RPCShared.Inquiries.Styles;
type Corners = Pick<Styles, 'corners'>['corners'];

export type CornerSelectorProps = Pick<
SelectProps<Corners>,
'value' | 'onChange'
>;

export const CornerSelector: React.FC<CornerSelectorProps> = ( {
	value,
	onChange,
} ) => {
	const labelId = useId();
	return (
		<FormControl fullWidth>
			<InputLabel id={ labelId }>Corners</InputLabel>
			<Select<Corners>
				labelId={ labelId }
				value={ value }
				label='Corners'
				onChange={ onChange }
			>
				<MenuItem value='square'>Square</MenuItem>
				<MenuItem value='rounded'>Round</MenuItem>
			</Select>
		</FormControl>
	);
};
