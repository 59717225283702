import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Accordion, Typography, AccordionSummary, Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import CustomMenuItemList from '../../../../mui/CustomMenuItemList/CustomMenuItemList';
import useProfileLinks from '../../../../config/useProfileLinks';
import getInitials from '../../../../lib/helpers/getInitials';

import styles from './ProfileAccordion.module.scss';
import addClickHandler from '../utils';
import { ChevronDownIcon } from '../../../../mui/Icons/index';

export default function ProfileAccordionContainer( {
	openState,
	toggleMobileMenu,
}: {
	openState: [boolean, Dispatch<SetStateAction<boolean>>];
	toggleMobileMenu: ( flag: boolean ) => void;
} ) {
	const menuItems = useProfileLinks();
	const user = useSelector( ( state: any ) => state.user );
	const [ isOpen, setIsOpen ] = openState;

	const configWithClickHandler = useMemo(
		() => addClickHandler( menuItems, toggleMobileMenu ),
		[ menuItems ]
	);

	return (
		<div className={ styles.root }>
			<Accordion expanded={ isOpen }>
				<AccordionSummary
					aria-controls='panel1bh-content'
					onClick={ () => setIsOpen( !isOpen ) }
					className={ classNames( styles.accordionToggle, {
						[ styles.accordionToggleActive ]: isOpen,
					} ) }
					expandIcon={ <ChevronDownIcon /> }
				>
					<Avatar src={ user.image }>{ getInitials( user ) }</Avatar>
					<Typography>
						{ ' ' }
						{ user.firstName } { user.lastName }
					</Typography>
				</AccordionSummary>
				<div>
					<CustomMenuItemList items={ configWithClickHandler } />
				</div>
			</Accordion>
		</div>
	);
}
