/* eslint max-len: 0 */
const Check = () => (
	<svg viewBox='0 0 24 24'>
		<path
			d='M19.697 6.47c.293-.293.768-.293 1.06 0 .267.267.29.683.072.977l-.072.084-11.13 11.108c-.264.263-.676.29-.97.076l-.084-.07-5.097-4.98c-.296-.289-.302-.764-.013-1.06.264-.27.68-.298.976-.084l.085.072 4.567 4.46L19.697 6.469z'
			transform='translate(-304 -1020) translate(144 183) translate(0 783) translate(0 53) translate(160 1)'
		/>
	</svg>
);

export default Check;
