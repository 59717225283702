import { IconButton, ListItemButton, ListItemProps } from '@mui/material';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Icon from '../../../../elements/icons';
import { Columns, CommonField } from '../types';
import MuiButton from '../../../../mui/MuiButton';
import { MuiModalProps } from '../../../../mui/MuiModal/MuiModal';
import styles from './useInquiriesFormField.module.scss';
import { RPCShared } from '@rockpapercoin/rpc-shared';

type Field = RPCShared.Inquiries.Field;

export const useInquiriesFormField = <T extends Field['id']>( {
	column,
	onUpdate,
	onReset,
	label: propsLabel,
	placeholder: propsPlaceholder,
	required: propsRequired,
	multiple: propsMultiple,
	...props
}: CommonField<T> & {
	onUpdate: ( props: {
		label: string;
		placeholder?: string;
		required: boolean;
		multiple?: boolean;
	} ) => void;
	onReset?: () => void;
	modalButton?: React.ReactNode;
} ) => {
	const [ open, setOpen ] = useState( false );
	const [ label, setLabel ] = useState( propsLabel );
	const [ placeholder, setPlaceholder ] = useState( propsPlaceholder );
	const [ required, setRequired ] = useState( propsRequired );
	const [ multiple, setMultiple ] = useState( propsMultiple );

	const reset = useCallback( () => {
		setLabel( propsLabel );
		setPlaceholder( propsPlaceholder );
		setMultiple( propsMultiple );
		setRequired( props.forceRequired || propsRequired || false );
		onReset?.();
	}, [
		propsLabel,
		propsPlaceholder,
		propsMultiple,
		props.forceRequired,
		propsRequired,
		onReset,
	] );

	// set local values from parent state when opening the modal
	useEffect( () => {
		if ( open ) {
			reset();
		}
	}, [ open, reset ] );

	const containerProps: Pick<ListItemProps, 'className' | 'secondaryAction'> = {
		className: classNames(
			styles.container,
			column === Columns.Both
				? styles.both
				: column === Columns.Left
					? styles.left
					: styles.right
		),
		secondaryAction: (
			<IconButton onClick={ () => setOpen( true ) }>
				<Icon type='pencil' className={ styles.icon } />
			</IconButton>
		),
	};

	const DragHandle: React.FC = ( props ) => (
		<ListItemButton className={ styles.dragHandleContainer } { ...props }>
			<Icon type='drag' className={ styles.icon } data-cy='drag-handle' />
		</ListItemButton>
	);

	const modalProps: Pick<MuiModalProps, 'title' | 'controls'> & {
		muiModalProps: Pick<MuiModalProps['muiModalProps'], 'open' | 'onClose'>;
	} = {
		title: props.type,
		muiModalProps: {
			open,
			onClose: () => {
				setOpen( false );
				reset();
			},
		},
		controls: (
			<MuiButton
				onClick={ () => {
					setOpen( false );
					onUpdate( { label, placeholder, multiple, required } );
				} }
			>
				Done
			</MuiButton>
		),
	};

	return {
		open,
		setOpen,
		containerProps,
		DragHandle,
		label,
		setLabel,
		placeholder,
		setPlaceholder,
		required,
		setRequired,
		multiple,
		setMultiple,
		modalProps,
		classNames: {
			modal: styles.modal,
			buttons: styles.buttons,
			addButton: classNames( styles.icon, styles.addButton ),
		},
	};
};
