import React from 'react';
import { IconButton } from '@mui/material';
import styles from './HelpButton.module.scss';
import QuestionMarkIconThin from '../Icons/QuestionMarkIconThin';
import { gql } from '@apollo/client';
import MuiSpinner from '../MuiSpinner';
import { useHelpButton_GetGlobalVariablesQuery } from './__generated__/HelpButton';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getGlobalVariablesQuery = gql`
	query HelpButton_getGlobalVariables {
		getGlobalVariables {
			HelpUrl
		}
	}
`;

export const HelpButton = () => {
	const { data: globalVariableData, loading: globalVariableLoading } =
		useHelpButton_GetGlobalVariablesQuery();
	const href = globalVariableData?.getGlobalVariables.HelpUrl;
	if ( globalVariableLoading || !href ) {
		return <MuiSpinner />;
	}
	return (
		<IconButton
			data-cy='help-btn'
			href={ href }
			target='_blank'
			size='small'
			rel='noreferrer'
		>
			<QuestionMarkIconThin className={ styles.icon } />
		</IconButton>
	);
};
