import React from 'react';
import { ButtonBase, Typography } from '@mui/material';
import styles from './SideNavButtonItem.module.scss';
import classNames from 'classnames';
import { CustomMenuButtonItemType } from '../../CustomMenuItem/CustomMenuItemComponent';

export default function SideNavButtonItem( {
	menuItem: { label, icon, isActive = false, onClick, className },
}: {
	menuItem: CustomMenuButtonItemType;
} ) {
	return (
		<ButtonBase
			onClick={ onClick }
			className={ classNames(
				styles.button,
				{ [ styles.buttonActive ]: isActive },
				className
			) }
		>
			{ icon }
			{ label && <Typography>{ label }</Typography> }
		</ButtonBase>
	);
}
