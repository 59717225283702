import { RPCShared } from '@rockpapercoin/rpc-shared';

type Field = RPCShared.Inquiries.Field;

export enum Columns {
	Both = 'Both',
	Left = 'Left',
	Right = 'Right',
}

type MutableProps = {
	setRequired: ( value: boolean ) => void;
	setLabel: ( value: string ) => void;
	setPlaceholder: ( value?: string ) => void;
	setMultiple: ( value?: boolean ) => void;
};

type FieldData = Omit<Field, 'id'> & {
	id: Exclude<Field['id'], 'firstNameTwo'>;
	required: boolean;
	label: string;
	placeholder?: string;
	multiple?: boolean;
	column: Columns;
	included: boolean;
};

type SecondNameField = Omit<Field, 'id'> & {
	id: Extract<Field['id'], 'firstNameTwo'>;
	required: boolean;
	label: string;
	placeholder?: string;
	multiple?: boolean;
	column: Columns;
	included: boolean;
	lastNameTwo: FieldData & MutableProps;
};

export type CommonFieldData<T extends Field['id']> = T extends 'firstNameTwo'
	? SecondNameField
	: FieldData;

export type CommonField<T extends Field['id']> = CommonFieldData<T> &
MutableProps;

export const commonFieldDataIsFirstNameTwo = (
	field: SecondNameField | FieldData
): field is SecondNameField => field.id === 'firstNameTwo';
