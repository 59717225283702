export enum Routes {
	Dashboard = '/',
	Clients = '/clients',
	Proposals = '/proposals',
	Contracts = '/contracts',
	Invoices = '/invoices',
	Team = '/admin?tab=team',
	Vendors = '/vendors',
	CustomerFolders = '/customerFolders',
	CompanyFolders = '/companyFolders',
	MyFolder = '/myFolder',
	ProposalTemplates = '/proposalTemplates',
	ContractTemplates = '/contractTemplates',
	InvoiceTemplates = '/invoiceTemplates',
	Resources = '/resources',
	Profile = '/profile',
	Admin = '/admin',
	Wallet = '/wallet',
	ClientUsers = '/ClientUsers',
	OrgUsers = '/OrgUsers',
	Organizations = '/Organizations',
	OrgOnboarding = '/orgOnboarding',
	ClientSignUp = '/ClientSignUp',
	OrgMemberSignUp = '/OrgMemberSignUp',
	Timelines = '/timelines',
	Inquiries = '/inquiries',
}
