import { getFileType, parseCentsFromDollars } from './index';
import rpcShared from '@rockpapercoin/rpc-shared';
const { regex: Regex } = rpcShared;
const validations = {
	/**
	 * checks that company name exists and has no special chars
	 *
	 * @param { string } [name] a string containing an organization name
	 */
	validateCompanyName: ( name ) => {
		if ( !name ) {
			return 'We need your business name.';
		}
	},

	/**
	 * checks to make sure the user has selected an option
	 *
	 * @param { String } mcc a merchant category code
	 *
	 * @returns { string | null }
	 */
	validateMCC: ( mcc ) => {
		if ( !mcc ) {
			return 'We need to know what kind of business you do.';
		}
		return null;
	},

	/**
	 * checks that a name exist and has no forbidden characters
	 *
	 * @param { string | null | undefined } name
	 *
	 * @returns { string | undefined }
	 */
	validateName: ( name ) => {
		if ( !name ) {
			return 'We need a name.';
		}
		if ( !Regex.lettersDashesAndApostrophesOnly.test( name ) ) {
			return 'Only letters, please';
		}
	},

	/**
	 * checks that a url exists and is formatted correctly
	 *
	 * @param { string } url
	 *
	 * @returns { string | null }
	 */
	validateURL: ( url ) => {
		if ( !url ) {
			return 'We need your business website.';
		}
		if ( !Regex.url.test( url.toLowerCase() ) ) {
			return 'Please enter a valid url.';
		}
		return null;
	},

	/**
	 * checks that the business type has been defined
	 *
	 * @param { string } businessType
	 * @returns { string | null }
	 */
	validateBusinessType: ( businessType ) => {
		if ( !businessType ) {
			return 'Let us know if the company files its taxes as an individual or a business.';
		}

		const validTypes = [
			'individual',
			'company',
			'government_entity',
			'non_profit',
		];

		if ( !validTypes.includes( businessType ) ) {
			return `${ businessType } is not a valid option`;
		}
		return null;
	},

	/**
	 * checks that a phone number is valid.
	 *
	 * @param { string | null } [phone]
	 *
	 * @returns { string | null }
	 */
	validatePhone: ( phone ) => {
		if ( !phone ) {
			return 'Please enter a phone number.';
		}
		if ( !Regex.isValidInternationalPhoneNumber( phone ) ) {
			return 'Please enter a valid phone number.';
		}
		return null;
	},

	/**
	 * validates address inputs
	 *
	 * @param { object } address containing the following fields
	 * @param { string } [address.line1]
	 * @param { string } [address.line2]
	 * @param { string } [address.city]
	 * @param { string } [address.state]
	 * @param { string } [address.postalCode]
	 * @param { string } [address.country]
	 * @param {*} type is it a business?
	 * @param { Boolean } should we check to see if it is a PO Box?
	 *
	 * @returns { { line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string } }
	 */
	validateAddress: ( address, type = null, testPO = false ) => {
		const err = {};

		if ( !address.line1 ) {
			err.line1 = `Please enter a${ type ? ' ' + type : 'n' } address.`;
		}
		if ( !Regex.address.test( address.line1 ) ) {
			err.line1 = `Whoops! Your${
				type ? ' ' + type : ''
			} address cannot contain special characters. Try again.`;
		}
		if ( testPO && address.line1 && Regex.POBox.test( address.line1 ) ) {
			err.line1 = 'Your address must be a physical address, not a PO Box.';
		}
		if ( !Regex.address.test( address.line2 ) ) {
			err.line2 = `Whoops! Your${
				type ? ' ' + type : ''
			} address cannot contain special characters. Try again.`;
		}
		if ( !address.country ) {
			err.country = `Don’t forget to enter your${
				type ? ' ' + type : ''
			} country!`;
		}
		if ( !address.state ) {
			err.state = `Don’t forget to enter your${ type ? ' ' + type : '' } state!`;
		}
		if ( !address.postalCode ) {
			err.postalCode = `We need your${ type ? ' ' + type : '' } zip!`;
		} else if ( !Regex.postalCode.test( address.postalCode ) ) {
			err.postalCode = 'Shucks, your zip code format is incorrect!';
		}
		if ( !address.city ) {
			err.city = 'Don’t forget to enter the city!';
		} else if ( !validations.noSpecialCharacters( [ err.city ] ) ) {
			err.city = 'Shucks, the city format is incorrect!';
		}
		return err;
	},

	/**
	 * tests to make sure that the first or second lines of an address block
	 * do not contain special chars or an address block.
	 *
	 * @param addressLine - either line 1 or line 2 of a given address
	 */
	validateAddressLine: ( addressLine ) => {
		let err = null;
		if ( !Regex.address.test( addressLine ) ) {
			err =
				'Whoops! Your address cannot contain special characters. Try again.';
		}
		if ( Regex.POBox.test( addressLine ) ) {
			err = 'Your address must be a physical address, not a PO Box.';
		}
		return err;
	},

	/**
	 * tests to ensure the postal code is correct
	 *
	 * @param postalCode - a us zip formatted postal code
	 */
	validatePostalCode: ( postalCode ) => {
		if ( !Regex.postalCode.test( postalCode ) ) {
			return 'Shucks, your zip code format is incorrect!';
		}
		return null;
	},

	/**
	 * checks to ensure the user accepted TOS
	 *
	 * @param { boolean } acceptance did we accept it?
	 * @param { string } type whose terms we are accepting
	 *
	 * @returns { string | null }
	 */
	validateTOS: ( acceptance, type ) => {
		if ( !acceptance ) {
			return `Oops, we need you to accept the${
				type ? ' ' + type : ''
			} terms of service.`;
		}
		return null;
	},

	/**
	 * checks that a taxID is valid
	 *
	 * @param { string } taxID
	 *
	 * @returns { string | null }
	 */
	validateTaxID: ( taxID ) => {
		if ( !taxID ) {
			return 'We need your Tax ID Number.';
		} else if ( taxID.length != 9 || !Regex.numbersOnly.test( taxID ) ) {
			return 'Uh oh! Your Tax ID format is invalid.';
		}
		return null;
	},

	/**
	 * checks that a file exists and is of appropriate type
	 *
	 * @param { object } image a file
	 *
	 * @returns { string | null }
	 */
	validateVerificationImage: ( image ) => {
		if ( !image ) {
			return 'Please upload owner verification (ID card, Drivers License or Passport)';
		}
		const filetype = getFileType( image );
		if (
			filetype != 'image/jpg' &&
			filetype != 'image/jpeg' &&
			filetype != 'image/png'
		) {
			return 'Whoops! That file type is not supported. Try a .jpg or .png';
		}
		return null;
	},

	/**
	 * checks the existence and validity of email address format
	 *
	 * @param { string | null | undefined } email
	 *
	 * @returns { string | undefined }
	 */
	validateEmail: ( email ) => {
		if ( !email ) {
			return 'Whoops! Please enter your email address';
		} else if ( !Regex.emailAddress.test( email.toLowerCase() ) ) {
			return 'Please double check your email format';
		}
	},

	/**
	 * Validates a password and password confirmation field.
	 *
	 * @param { string } newPassword - A password entered.
	 * @param { string } confirmedPassword - A password re-entered.
	 *
	 * @returns { { password?: string; repassword?: string; } }
	 */
	validatePasswords: ( newPassword, confirmedPassword ) => {
		const passwordValidation = rpcShared.users.validation.password(
			newPassword,
			confirmedPassword
		);
		const badPasswordConfirmErrorStrings = [ 'Please re-enter your password', 'Passwords don\'t match', ];
		const confirmedPasswordBad = badPasswordConfirmErrorStrings.includes(
			passwordValidation
		)
			? passwordValidation
			: undefined;

		const newPasswordBad = !badPasswordConfirmErrorStrings.includes(
			passwordValidation
		)
			? passwordValidation
			: undefined;
		return {
			password: newPasswordBad,
			repassword: confirmedPasswordBad,
		};
	},

	/**
	 * checks that all fields of dob are populated
	 *
	 * @param { Object } dob
	 * @param { String } dob.day
	 * @param { String } dob.month
	 * @param { String } dob.year

	 *
	 * @returns {<object>}
	 */
	validateStripeDOB: ( dob ) => {
		const err = {};
		if ( !dob.day ) {
			err.day = 'Please provide day of birth.';
		}
		if ( !dob.month ) {
			err.month = 'Please provide month of birth';
		}
		if ( !dob.year ) {
			err.year = 'Please provide year of birth';
		}
		return err;
	},

	/**
	 * checks that the string is not empty, and has only 4 numbers
	 *
	 * @param { string } last4
	 *
	 * @returns { string | null }
	 */
	validateSSNLast4: ( last4 ) => {
		if ( !last4 ) {
			return 'Please enter the last 4 digits of the owner\'s social security number.';
		} else if ( last4.length != 4 || !Regex.numbersOnly.test( last4 ) ) {
			return 'Shoot. Your SSN\'s format is invalid.';
		}
		return null;
	},

	/**
	 * checks that the string is not empty, and has only 9 numbers
	 *
	 * @param { string } last4
	 *
	 * @returns { string | null }
	 */
	validateSSN: ( ssn ) => {
		if ( !ssn ) {
			return 'Please enter this person\'s social security number.';
		} else if ( ssn.length != 9 || !Regex.numbersOnly.test( ssn ) ) {
			return 'Shoot. Your SSN\'s format is invalid.';
		}
		return null;
	},

	/**
	 * checks that a title exists and has no funny business
	 *
	 * @param { string } title
	 *
	 * @returns { string | null }
	 */
	validateTitle: ( title ) => {
		if ( !title ) {
			return 'Oops, we need the title of your business representative.';
		} else if ( !validations.noSpecialCharacters( [ title ] ) ) {
			return 'The title cannot contain special characters.';
		}
		return null;
	},

	/**
	 * Validates any number of strings to ensure that contain no special characters or numbers.
	 *
	 * @param {String[]} args - An array of strings.
	 */
	noSpecialCharacters: ( args ) => {
		for ( const field of args ) {
			if ( !Regex.lettersOnly.test( field ) ) {
				return false;
			}
		}
		return true;
	},

	/**
	 * Validates any number of strings to ensure that contain only letters, spaces, dashes or apostrophes.
	 *
	 * @param {String[]} args - An array of strings.
	 *
	 * @returns {Boolean}
	 */
	validateNames: ( args ) => {
		for ( const field of args ) {
			if ( !Regex.lettersDashesAndApostrophesOnly.test( field ) ) {
				return false;
			}
		}
		return true;
	},

	/**
	 * Validates a client and their partner's first and last names.
	 *
	 * @param { {
	 *   firstNameOne: string,
	 *   lastNameOne: string,
	 *   firstNameTwo: string,
	 *   lastNameTwo: string
	 * } } arg
	 * @returns { Object } An object with corresponding error messages for each name
	 */
	validateClientNames: ( {
		firstNameOne,
		lastNameOne,
		firstNameTwo,
		lastNameTwo,
	} ) => {
		const characterLimit = 20;
		const err = {};

		if ( !firstNameOne ) {
			err.firstNameOne = 'Uh oh. We need your first name!';
		}

		if ( !lastNameOne ) {
			err.lastNameOne = 'Uh oh. We need your last name!';
		}

		if ( firstNameOne ) {
			if ( !validations.validateNames( [ firstNameOne ] ) ) {
				err.firstNameOne =
					'Please make sure your first name only contains characters';
			}

			if ( firstNameOne.length > characterLimit ) {
				err.firstNameOne =
					'Your first name exceeds the character limit (20 characters).';
			}
		}

		if ( lastNameOne ) {
			if ( !validations.validateNames( [ lastNameOne ] ) ) {
				err.lastNameOne =
					'Please make sure your last name only contains characters';
			}

			if ( lastNameOne.length > characterLimit ) {
				err.lastNameOne =
					'Your last name exceeds the character limit (20 characters).';
			}
		}

		if ( firstNameTwo || lastNameTwo ) {
			if ( !firstNameTwo ) {
				err.firstNameTwo = 'Uh oh. We need your partner\'s first name!';
			}

			if ( !lastNameTwo ) {
				err.lastNameTwo = 'Uh oh. We need your partner\'s last name!';
			}

			if ( firstNameTwo ) {
				if ( !validations.validateNames( [ firstNameTwo ] ) ) {
					err.firstNameTwo =
						'Please make sure your partner\'s first name only contains characters';
				}

				if ( firstNameTwo.length > characterLimit ) {
					err.firstNameTwo =
						'Your partner\'s first name exceeds the character limit (20 characters).';
				}
			}

			if ( lastNameTwo ) {
				if ( !validations.validateNames( [ lastNameTwo ] ) ) {
					err.lastNameTwo =
						'Please make sure your partner\'s last name only contains characters';
				}

				if ( lastNameTwo.length > characterLimit ) {
					err.lastNameTwo =
						'Your partner\'s last name exceeds the character limit (20 characters).';
				}
			}
		}

		return err;
	},

	/**
	 * Validates an org user's first and last name
	 *
	 * @param { String } [firstName] - The org user's first name
	 * @param { String } [lastName] - The org user's last name
	 *
	 * @returns { { firstName?: string, lastName?: string } } An object with corresponding error messages for each name
	 */
	validateOrgUserName: ( firstName, lastName ) => {
		const characterLimit = 20;
		const err = {};

		if ( !firstName ) {
			err.firstName = 'Uh oh. We need your first name!';
		}

		if ( !lastName ) {
			err.lastName = 'Uh oh. We need your last name!';
		}

		if ( firstName ) {
			if ( !validations.validateNames( [ firstName ] ) ) {
				err.firstName =
					'Please make sure your first name only contains characters';
			}

			if ( firstName.length > characterLimit ) {
				err.firstName =
					'Your first name exceeds the character limit (20 characters).';
			}
		}

		if ( lastName ) {
			if ( !validations.validateNames( [ lastName ] ) ) {
				err.lastName =
					'Please make sure your last name only contains characters';
			}

			if ( lastName.length > characterLimit ) {
				err.lastName =
					'Your last name exceeds the character limit (20 characters).';
			}
		}

		return err;
	},

	/**
	 * Validates an org's selected services
	 *
	 * @param { { name: string, id: string }[] | null } [services] - The selected services
	 *
	 * @returns { { selectedServices?: string } } An object with corresponding error message
	 */
	validateSelectedServices: ( services ) => {
		if ( !services || services.length === 0 ) {
			return {
				selectedServices:
					'We know you do it all but please choose at least one service/category!',
			};
		}
	},

	/**
	 * Validates that the event date is in the future.
	 *
	 * @param { DateTime } eventDate - An event date
	 *
	 * @returns { String }
	 */
	validateEventDate: ( eventDate ) => {
		if ( eventDate < new Date() ) {
			return 'We need something to look forward to! Please enter a future event date.';
		}
	},

	/**
	 * Validates new ACH account creation fields
	 *
	 * @param {Object} achObject - New ACH object entry
	 * @param {String} achObject.accountHolderType - type of account
	 * @param {String} achObject.accountHolderName - name of the account holder
	 * @param {String} achObject.country - country
	 * @param {String|Number} achObject.routingNumber - bank routing number
	 * @param {String|Number} achObject.accountNumber - bank account number
	 * @param {String} achObject.nickname - account nickname
	 * @param {Boolean} newAccount - whether a new account is being created
	 *
	 * @returns - Object containing error messages to be displayed for each field
	 */
	validateBankAccount: ( achObject, newAccount ) => {
		const {
			accountHolderType,
			accountHolderName,
			country,
			routingNumber,
			accountNumber,
			nickname,
		} = achObject;
		const err = {};

		if ( !accountHolderType ) {
			err.accountHolderType = 'We need the bank account type!';
		}

		if ( !accountHolderName ) {
			err.accountHolderName = 'We need the account holder\'s name!';
		}

		if ( !country ) {
			err.country = 'Don’t forget to enter the country!';
		}

		if ( newAccount ) {
			if ( !routingNumber ) {
				err.routingNumber = 'Your bank routing number is missing!';
			} else if ( routingNumber.toString().length !== 9 ) {
				err.routingNumber = 'Darn! Your routing number is invalid.';
			}

			if ( !accountNumber ) {
				err.accountNumber = 'We need your bank account number!';
			} else if (
				accountNumber.toString().length < 7 ||
				accountNumber.toString().length > 18
			) {
				err.accountNumber = 'Uh oh! Your bank account number is invalid.';
			}
		}

		if ( nickname && nickname.length > 20 ) {
			err.nickname =
				'It’s not you, it’s us. Your nickname exceeds the character limit (20 characters).';
		}

		return err;
	},

	/**
	 * Validates social media fields for the appropriate URL.
	 * Would return an error in the facebook field if user inputs 'www.twitter.com/handle_name'
	 *
	 * @param {Object} socialMediaFields - social media fields object
	 * @param { string | undefined | null } socialMediaFields.facebook - field input to be validated
	 * @param { string | undefined | null } socialMediaFields.pinterest - field input to be validated
	 * @param { string | undefined | null } socialMediaFields.twitter - field input to be validated
	 * @param { string | undefined | null } socialMediaFields.instagram - field input to be validated
	 *
	 * @returns - Object containing error messages to be displayed for each field
	 */
	validateSocialMediaFields: ( socialMediaFields ) => {
		const err = {};

		for ( const platform in socialMediaFields ) {
			if ( !socialMediaFields[ platform ] ) {
				socialMediaFields[ platform ] = '';
			}

			const handle = socialMediaFields[ platform ];

			if ( handle.match( Regex.emailAddress ) ) {
				err[ platform ] =
					'Please check the url formatting or only enter your handle.';
			} else {
				let strippedHandle;
				const platformUrlPattern =
					Regex.generateRegexForSocialMediaPlatformUrl( platform );

				if ( platformUrlPattern.test( handle ) ) {
					strippedHandle = handle.replace( platformUrlPattern, '' );
					if ( strippedHandle === '' ) {
						err[ platform ] =
							'Please check the url formatting including your handle, or only enter your handle.';
					}
				} else {
					strippedHandle = handle;
				}

				if ( Regex.url.test( strippedHandle ) && strippedHandle.includes( 'com' ) ) {
					err[ platform ] =
						'Please check the url formatting or only enter your handle.';
				}
			}
		}

		return err;
	},

	/**
	 * checks that an amount exists, is a number, between 25 and 100, and has no more than 2 decimal places.
	 *
	 */
	validatePercentOwnership: ( amount ) => {
		if ( !amount ) {
			return 'Please provide percentage of ownership';
		}
		if ( !Regex.numberWithMaxTwoDecimals.test( amount ) ) {
			return 'Please enter a number with a maximum of two decimal places.';
		}
		const intAmount = parseInt( amount );
		if ( intAmount > 100 || intAmount < 25 ) {
			return 'Uh oh, that\'s not a valid amount.';
		}
	},

	/**
	 * checks that a date exists
	 *
	 * @param { Date } date
	 *
	 * @returns { String || Void }
	 */
	validateDate: ( date ) => {
		if ( !date || !( date instanceof Date ) || isNaN( date.valueOf() ) ) {
			return 'Please select a date.';
		}
	},
	/**
	 * Validates that a source file is present.
	 */
	validateContractTemplateSourceFile: ( sourceFile ) => {
		const fileIsPresent = !!sourceFile;
		if ( !fileIsPresent ) {
			return 'File required';
		}
	},

	/**
	 * checks that a payment method exists
	 *
	 * @param { String } method - the payment method
	 *
	 * @returns { String || Void }
	 */
	validatePaymentMethod: ( method ) => {
		if ( !method ) {
			return 'Please select a payment method.';
		}
	},

	/**
	 * checks that an amount is correctly formatted, greater than zero, and less than the amount paid
	 *
	 * @param { String } amountRefunded - the amount to be refunded
	 * @param { String } amountPaid - the amount originally paid on the invoice
	 *
	 * @returns { String || Void }
	 */
	validateAmountRefunded: ( amountRefunded, amountPaid ) => {
		if ( !Regex.validCurrency.test( amountRefunded ) ) {
			return 'Oops, that doesn\'t look like a valid amount.';
		}
		const refunded = parseCentsFromDollars( amountRefunded );
		const paid = parseCentsFromDollars( amountPaid );
		if ( !refunded ) {
			return 'The amount refunded must be greater than zero.';
		}
		if ( refunded > paid ) {
			return 'The amount refunded must be less than the amount paid.';
		}
	},

	/**
	 * Validates that a title is present.
	 */
	validateContractTemplateTitle: ( title ) => {
		const titleIsValid = !!title;
		if ( !titleIsValid ) {
			return 'Title required';
		}
	},

	/**
	 * Validates that the user completes the text field if chooses 'Other' as option.
	 */
	validateOtherExposure: ( option, text ) => {
		if ( option && option === 'Other' && ( !text || text?.length <= 1 ) ) {
			return 'Please, let us know how you found us!';
		}
	},
};

export default validations;
