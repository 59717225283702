import React from 'react';
import AddEvent from './add-event.js';
import ArrowRight from './ArrowRight';
import BellOff from './bell-off.js';
import BellOn from './bell-on.js';
import Calendar from './calendar';
import Camera from './camera.js';
import Cash from './cash.js';
import Check from './check.js';
import CheckFill from './check-fill';
import Checked from './checked.js';
import ChevronDown from './chevron-down.js';
import ChevronLeft from './chevron-left';
import ChevronLeftSquare from './chevron-left-square';
import ChevronRight from './chevron-right';
import ChevronRightSquare from './chevron-right-square';
import ChevronUp from './chevron-up.js';
import Circle from './circle';
import CircleClose from './circle-close.js';
import Client from './client';
import CloseSmall from './close-small.js';
import CloseRegular from './close-regular.js';
import Computer from './computer';
import Connected from './connected';
import Contract from './contract.js';
import ContractOverdue from './ContractOverdue';
import ContractSigned from './contract-signed.js';
import CreditCard from './credit-card';
import Cross from './cross.js';
import Decline from './Decline';
import DisableAutopay from './disable-autopay.js';
import Dollar from './Dollar';
import Download from './download';
import Draft from './draft.js';
import Due from './Due';
import Edit from './edit.js';
import Email from './email';
import ExistingUser from './existing-user.js';
import Export from './export.js';
import Facebook from './facebook';
import Faq from './faq.js';
import Folder from './folder';
import GoogleDrive from './google-drive.js';
import Home from './home';
import Hide from './Hide';
import Image from './image.js';
import Instagram from './instagram';
import Invoice from './invoice.js';
import InvoiceDue from './InvoiceDue';
import InvoiceReceived from './invoice-received.js';
import LearnMore from './learn-more';
import Link from './Link';
import Mail from './mail.js';
import Menu from './menu.js';
import MicroTransaction from './micro-transaction.js';
import Minus from './minus.js';
import More from './more.js';
import MoveFolder from './move-folder.js';
import MP3 from './mp3.js';
import NewContract from './new-contract.js';
import NewInvoice from './new-invoice.js';
import NewProposal from './new-proposal';
import NewUser from './new-user.js';
import Organization from './organization';
import PaperClip from './paperclip.js';
import Pay from './pay.js';
import Percentage from './percentage.js';
import Phone from './phone.js';
import PDF from './pdf.js';
import Picture from './picture.js';
import Pin from './pin';
import Pinterest from './pinterest.js';
import PlaidLogo from './plaid-logo.js';
import Plus from './plus';
import ProfilePlaceHolder from './profilePlaceHolder.js';
import QuestionMark from './question-mark.js';
import Save from './save.js';
import Search from './search.js';
import Send from './send.js';
import Settings from './settings.js';
import Sheet from './sheet';
import SignCheck from './SignCheck';
import Signature from './signature.js';
import Slide from './slide.js';
import SmallArrow from './small-arrow.js';
import Star from './star.js';
import ContractTemplate from './template-contract.js';
import InvoiceTemplate from './TemplateInvoice';
import Text from './text.js';
import Trash from './trash.js';
import Twitter from './twitter';
import Unchecked from './unchecked.js';
import Update from './update.js';
import Vendor from './vendor.js';
import Video from './video.js';
import View from './View';
import Void from './void.js';
import Warning from './warning.js';
import Warning2 from './warning-2.js';
import Website from './website';
import CircleQuestionmark from './circle-questionmark.js';
import StarOutline from './star-outline.js';
import AcceptConnection from './accept-connection.js';
import FileAudio from './file-audio';
import FileImage from './file-image';
import FilePDF from './file-pdf';
import FilePresentation from './file-presentation';
import FileTable from './file-table';
import FileText from './file-text';
import FileVideo from './file-video';
import FileFolder from './file-folder';
import Expand from './expand';
import Collapse from './collapse';
import Pencil from './pencil.js';
import QuickPayment from './QuickPayment';
import MoneyBank from './money-bank';
import Drag from './drag.tsx';
import EditDetails from './editDetails.tsx';
import UploadFile from './UploadFile';

const Icon = ( props ) => {
	const { type } = props;

	const Components = {
		'accept-connection': AcceptConnection,
		'add-event': AddEvent,
		'arrow-right': ArrowRight,
		'bell-off': BellOff,
		'bell-on': BellOn,
		calendar: Calendar,
		camera: Camera,
		cash: Cash,
		check: Check,
		'check-fill': CheckFill,
		checked: Checked,
		'chevron-down': ChevronDown,
		'chevron-left': ChevronLeft,
		'chevron-left-square': ChevronLeftSquare,
		'chevron-right': ChevronRight,
		'chevron-right-square': ChevronRightSquare,
		'chevron-up': ChevronUp,
		circle: Circle,
		'circle-close': CircleClose,
		'circle-questionmark': CircleQuestionmark,
		'close-small': CloseSmall,
		client: Client,
		'close-regular': CloseRegular,
		computer: Computer,
		connected: Connected,
		contract: Contract,
		'contract-overdue': ContractOverdue,
		'contract-signed': ContractSigned,
		'credit-card': CreditCard,
		cross: Cross,
		decline: Decline,
		'disable-autopay': DisableAutopay,
		dollar: Dollar,
		download: Download,
		draft: Draft,
		drag: Drag,
		due: Due,
		edit: Edit,
		editDetails: EditDetails,
		email: Email,
		'existing-user': ExistingUser,
		export: Export,
		facebook: Facebook,
		faq: Faq,
		'file-audio': FileAudio,
		'file-folder': FileFolder,
		'file-image': FileImage,
		'file-pdf': FilePDF,
		'file-presentation': FilePresentation,
		'file-table': FileTable,
		'file-text': FileText,
		'file-video': FileVideo,
		folder: Folder,
		googleDrive: GoogleDrive,
		home: Home,
		hide: Hide,
		image: Image,
		instagram: Instagram,
		invoice: Invoice,
		'invoice-due': InvoiceDue,
		'invoice-received': InvoiceReceived,
		'learn-more': LearnMore,
		link: Link,
		mail: Mail,
		menu: Menu,
		'micro-transaction': MicroTransaction,
		minus: Minus,
		'money-bank': MoneyBank,
		more: More,
		'move-folder': MoveFolder,
		mp3: MP3,
		'new-contract': NewContract,
		'new-invoice': NewInvoice,
		'new-proposal': NewProposal,
		'new-user': NewUser,
		organization: Organization,
		paperclip: PaperClip,
		pay: Pay,
		pdf: PDF,
		percentage: Percentage,
		'star-outline': StarOutline,
		phone: Phone,
		picture: Picture,
		pin: Pin,
		pinterest: Pinterest,
		'plaid-logo': PlaidLogo,
		plus: Plus,
		'profile-placeholder': ProfilePlaceHolder,
		'question-mark': QuestionMark,
		save: Save,
		search: Search,
		send: Send,
		settings: Settings,
		sheet: Sheet,
		'sign-check': SignCheck,
		signature: Signature,
		slide: Slide,
		'small-arrow': SmallArrow,
		star: Star,
		'template-contract': ContractTemplate,
		'template-invoice': InvoiceTemplate,
		text: Text,
		trash: Trash,
		twitter: Twitter,
		unchecked: Unchecked,
		update: Update,
		'upload-file': UploadFile,
		vendor: Vendor,
		video: Video,
		view: View,
		void: Void,
		warning: Warning,
		'warning-2': Warning2,
		website: Website,
		expand: Expand,
		collapse: Collapse,
		pencil: Pencil,
		quickPayment: QuickPayment,
	};

	if ( !type || !Components[ type ] ) {
		return <div className='icon-not-found' />;
	}

	const Component = Components[ type ];

	return <Component { ...props } />;
};

export default Icon;
