import { Typography } from '@mui/material';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState, useCallback } from 'react';
import { useDeviceDetection } from '../../hooks/useDeviceDetection';
import MuiPaperBackButton from '../../mui/MuiPaper/MuiPaperBackButton';
import MuiStepper from '../../mui/MuiStepper/MuiStepper';
import styles from './InquiriesConfiguration.module.scss';
import { InquiriesConfigurationForm } from './InquiriesConfigurationForm';
import { InquiriesStylingForm } from './InquiriesStylingForm';

enum InquiriesConfigurationSteps {
	Setup = 'setup',
	Style = 'style',
	Publish = 'publish',
}

type StepLabelsCastType = Record<
keyof typeof InquiriesConfigurationSteps,
string
>;

const stepLabels: Record<InquiriesConfigurationSteps, string> = {
	[ InquiriesConfigurationSteps.Setup ]: 'Setup',
	[ InquiriesConfigurationSteps.Style ]: 'Style',
	[ InquiriesConfigurationSteps.Publish ]: 'Publish',
};

export const queryValueIsInquiriesConfigurationStep = (
	potentialStep: string | string[] | undefined | InquiriesConfigurationSteps
): potentialStep is InquiriesConfigurationSteps =>
	typeof potentialStep === 'string' &&
	Object.values<string>( InquiriesConfigurationSteps ).includes( potentialStep );

export const Title: Record<InquiriesConfigurationSteps, string> = {
	[ InquiriesConfigurationSteps.Setup ]: 'Inquiries Form Setup',
	[ InquiriesConfigurationSteps.Style ]: 'Inquiries Form Styling',
	[ InquiriesConfigurationSteps.Publish ]: 'Inquiries Form Publish Markup',
};

export type InquiriesConfigurationStepsKeys = `${ InquiriesConfigurationSteps }`;

const getNextStep = ( step: InquiriesConfigurationStepsKeys ) => {
	switch ( step ) {
		case InquiriesConfigurationSteps.Setup:
			return InquiriesConfigurationSteps.Style;
		case InquiriesConfigurationSteps.Style:
			return InquiriesConfigurationSteps.Publish;
	}
};

const getPreviousStep = ( step: InquiriesConfigurationStepsKeys ) => {
	switch ( step ) {
		case InquiriesConfigurationSteps.Style:
			return InquiriesConfigurationSteps.Setup;
		case InquiriesConfigurationSteps.Publish:
			return InquiriesConfigurationSteps.Style;
	}
};

enum Directions {
	Next = 'next',
	Back = 'back',
}

const getPath = (
	direction: Directions,
	currentStep: InquiriesConfigurationStepsKeys
) =>
	direction === Directions.Back
		? `/admin/inquiries/${ getPreviousStep( currentStep ) }`
		: `/admin/inquiries/${ getNextStep( currentStep ) }`;

export type InquiriesConfigurationProps = {
	goggleFontsApiKey: string;
	step: InquiriesConfigurationSteps;
};

export const InquiriesConfiguration: React.FC<InquiriesConfigurationProps> = ( {
	goggleFontsApiKey,
	step,
} ) => {
	const router = useRouter();
	const { isMobile } = useDeviceDetection();
	// cook down some values we'll use with the stepper
	const activeStep = Object.values(
		InquiriesConfigurationSteps as Record<string, string>
	).indexOf( step );

	const backButtonHref = '/admin?tab=settings';

	// So we can make the buttons spinners when one is clicked
	const [ loading, setLoading ] = useState( false );
	useEffect( () => {
		setLoading( false );
	}, [ step ] );

	// begin all of the actual work of onboarding the user
	const onNext = useCallback(
		async () => {
			/*if ( step === InquiriesConfigurationSteps.Setup ) {
			return await onSetupNext();
		} else if ( step === InquiriesConfigurationSteps.Style ) {
			return await onStyleNext();
		} else if ( step === InquiriesConfigurationSteps.Publish ) {
			return await onPublishNext();
		}*/
			return true;
		},
		[
			/*onSetupNext,
		onStyleNext,
		onPublishNext,
		step,*/
		]
	);

	const handleOnNext = useCallback( async () => {
		setLoading( true );
		if ( step === InquiriesConfigurationSteps.Publish ) {
			await router.push( backButtonHref );
			setLoading( false );
		} else {
			const passesValidation = await onNext();
			if ( passesValidation ) {
				await router.push( getPath( Directions.Next, step ) );
			} else {
				setLoading( false );
			}
		}
	}, [
		onNext,
		router,
		step,
		backButtonHref
	] );

	// handle clicking the back button - remember that we use the onBack handler of the step we're _going in to__
	const onBack = useCallback(
		async () => {
			/*if ( step === InquiriesConfigurationSteps.Style ) {
			return onSetupBack();
		} else if ( step === InquiriesConfigurationSteps.Publish ) {
			return onStyleBack();
		}*/
		},
		[
			/*onSetupBack,
		onStyleBack,
		step,*/
		]
	);

	const handleOnBack = useCallback( async () => {
		setLoading( true );
		await onBack();
		router.push( getPath( Directions.Back, step ) );
	}, [
		onBack,
		router,
		step
	] );

	return (
		<>
			<Head>
				<title>{ Title[ step ] }</title>
			</Head>
			<main className={ styles.main }>
				<MuiPaperBackButton href={ backButtonHref } text='Admin' />
				{ isMobile ? (
					<Typography className={ styles.desktopOnly }>
						Your inquiry form can only be configured on desktop.
					</Typography>
				) : (
					<MuiStepper
						className={ styles.stepper }
						steps={ InquiriesConfigurationSteps }
						labels={ stepLabels as unknown as StepLabelsCastType }
						activeStep={ activeStep }
						onNext={ handleOnNext }
						onBack={ handleOnBack }
						loading={ loading }
					>
						{ step === InquiriesConfigurationSteps.Setup ? (
							<InquiriesConfigurationForm />
						) : null }
						{ step === InquiriesConfigurationSteps.Style ? (
							<InquiriesStylingForm googleFontsApiKey={ goggleFontsApiKey } />
						) : null }
						{ step === InquiriesConfigurationSteps.Publish ? (
							<Typography>{ step }</Typography>
						) : null }
					</MuiStepper>
				) }
			</main>
		</>
	);
};
