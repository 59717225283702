const ContactScalarFields = [
	'createdAt',
	'eventDate',
	'firstName',
	'lastName',
	'phone',
	'id',
	'status',
	'updatedAt',
	'archivedByVendor',
];

const ContactScalarFieldsNoSensitiveInformation = [
	'createdAt',
	'eventDate',
	'firstName',
	'lastName',
	'id',
	'status',
	'updatedAt',
];

export { ContactScalarFields, ContactScalarFieldsNoSensitiveInformation };
