import {
	Box,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextFieldProps,
	Typography,
} from '@mui/material';
import React from 'react';
import MuiTextFieldText from '../../../mui/MuiTextField/MuiTextFieldText/MuiTextFieldText';
import { exposureOptionFromString } from './helpers';
import styles from './FinishUp.module.scss';
import MuiButton from '../../../mui/MuiButton';
import Icon from '../../../elements/icons';
import { ReferralCategory } from '../../../types/generated';
import { CloseIcon } from '../../../mui/Icons';

export type FinishUpProps = {
	categoryExposure?: ReferralCategory;
	setCategoryExposure: React.Dispatch<
	React.SetStateAction<ReferralCategory | undefined>
	>;
	categoryExposureValidation?: string;
	otherExposure?: string;
	setOtherExposure: React.Dispatch<React.SetStateAction<string | undefined>>;
	promoCode?: string;
	setPromoCode: React.Dispatch<React.SetStateAction<string | undefined>>;
	promoCodeValidation?: string;
	verifyPromoCode: () => Promise<boolean>;
	promoCodeVerificationLoading?: boolean;
	promoCodeVerbiage?: string;
	onBlur?: TextFieldProps['onBlur'];
};
const FinishUp: React.FC<FinishUpProps> = ( {
	categoryExposure,
	setCategoryExposure,
	categoryExposureValidation,
	otherExposure,
	setOtherExposure,
	promoCode,
	setPromoCode,
	verifyPromoCode,
	promoCodeValidation,
	promoCodeVerificationLoading,
	promoCodeVerbiage,
	onBlur,
} ) => (
	<>
		<Typography variant='h1' component='h1'>
			Almost done!
		</Typography>
		<Box className={ styles.form }>
			<FormControl
				fullWidth
				error={ !!categoryExposureValidation }
				variant='outlined'
			>
				<InputLabel htmlFor={ 'categoryExposure' }>
					How did you hear about us? (optional)
				</InputLabel>
				<Select<ReferralCategory>
					id='categoryExposure'
					label='How did you hear about us? (optional)'
					value={ categoryExposure || '' }
					onChange={ ( e ) =>
						setCategoryExposure( exposureOptionFromString( e.target.value ) )
					}
					onBlur={ onBlur }
					inputProps={ {
						'data-cy': 'categoryExposure',
					} }
					autoFocus
				>
					<MenuItem value=''>None</MenuItem>
					{ Object.entries( ReferralCategory ).map( ( [ value, label ] ) => (
						<MenuItem key={ value } value={ value }>
							{ label }
						</MenuItem>
					) ) }
				</Select>
			</FormControl>
			{ categoryExposure === ReferralCategory.Other ? (
				<MuiTextFieldText
					fullWidth
					label='Other'
					placeholder="We're curious"
					value={ otherExposure || '' }
					onChange={ ( e ) => setOtherExposure( e.target.value ) }
					onBlur={ onBlur }
					autoFocus
					errorText={ categoryExposureValidation }
					inputProps={ {
						'data-cy': 'otherExposure',
					} }
				/>
			) : (
				<Box className={ styles.desktopOnly } />
			) }
			{ /* made this input "clearable" with a custom IconButton that appears on hover */ }
			<MuiTextFieldText
				fullWidth
				label='Promo code (optional)'
				value={ promoCode || '' }
				onChange={ ( e ) => setPromoCode( e.target.value ) }
				onBlur={ onBlur }
				errorText={ promoCodeValidation }
				className={ styles.promoCodeInput }
				InputProps={ {
					endAdornment: (
						<IconButton
							onClick={ () => setPromoCode( undefined ) }
							className={ styles.clearButton }
						>
							<CloseIcon className={ styles.icon } />
						</IconButton>
					),
				} }
				inputProps={ {
					'data-cy': 'promoCode',
				} }
			/>
			<MuiButton
				variant='text'
				onClick={ () => verifyPromoCode() }
				loading={ promoCodeVerificationLoading }
				data-cy='verifyPromoCode'
			>
				Verify
			</MuiButton>
			{ promoCodeVerbiage ? (
				<Typography className={ styles.promoCodeSuccess }>
					<Icon type='connected' />
					{ promoCodeVerbiage }
				</Typography>
			) : undefined }
		</Box>
	</>
);

export default FinishUp;
