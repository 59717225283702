import {
	Box,
	Button,
	Divider,
	inputBaseClasses,
	inputLabelClasses,
	TextField,
	Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { MuiColorInput } from 'mui-color-input';
import dynamic from 'next/dynamic';
const FontPicker = dynamic( () => import( './FontPicker' ), {
	ssr: false,
} );
import rpcShared, { RPCShared } from '@rockpapercoin/rpc-shared';
import styles from './InquiriesStylingForm.module.scss';
import { CornerSelector } from './CornerSelector';

const families = [
	'Barlow',
	'Baskervville SC',
	'Comic Neue',
	'Cormorant Garamond',
	'Dancing Script',
	'Darker Grotesque',
	'EB Garamond',
	'Gentium Plus',
	'Great Vibes',
	'Gruppo',
	'Italianno',
	'Libre Baskerville',
	'Lora',
	'Maven Pro',
	'Merriweather',
	'Open Sans',
	'Parisienne',
	'Patua One',
	'Playfair Display',
	'Playwrite US Modern',
	'Poiret One',
	'Poppins',
	'PT Sans',
	'PT Serif',
	'Public Sans',
	'Quicksand',
	'Raleway',
	'Reenie Beanie',
	'Roboto',
	'Roboto Serif',
	'Sacramento',
	'Sanchez',
	'Shadows Into Light',
	'Tenor Sans',
	'The Nautigal',
];

type Styles = RPCShared.Inquiries.Styles;
type Colors = Omit<Styles, 'fontFamily' | 'corners'>;
type Corners = Pick<Styles, 'corners'>['corners'];

const getLabelById = ( id: keyof Styles ) => {
	if ( id === 'fontFamily' ) {
		return 'Font';
	} else if ( id === 'instructions' ) {
		return 'Instructions';
	} else if ( id === 'fieldLabel' ) {
		return 'Field label';
	} else if ( id === 'fieldOutline' ) {
		return 'Field outline';
	} else if ( id === 'fieldInputText' ) {
		return 'Field Input text';
	} else if ( id === 'buttonFill' ) {
		return 'Button fill';
	} else if ( id === 'buttonText' ) {
		return 'Button text';
	} else if ( id === 'corners' ) {
		return 'Corners';
	}
};

const initialColors = Object.entries( rpcShared.inquiries.styles ).reduce(
	( acc, [ key, value ] ) =>
		[ 'fontFamily', 'corners' ].includes( key )
			? acc
			: { ...acc, [ key as keyof Colors ]: value },
	{}
	// had to end up casting here because I couldn't help reduce get to match the type because eof the key
) as Colors;

export type InquiriesStylingFormProps = {
	googleFontsApiKey: string;
};

export const InquiriesStylingForm: React.FC<InquiriesStylingFormProps> = ( {
	googleFontsApiKey,
} ) => {
	const [ fontFamily, setFontFamily ] = useState( 'Open Sans' );
	const [ colors, setColors ] = useState( initialColors );
	const [ corners, setCorners ] = useState<Corners>( 'rounded' );
	const borderRadius = corners === 'rounded' ? '0.25rem' : 0;
	return (
		<Box className={ styles.container }>
			<Box className={ styles.column }>
				<Typography variant='h6'>Style</Typography>
				<FontPicker
					apiKey={ googleFontsApiKey }
					value={ fontFamily }
					onChange={ ( value ) => setFontFamily( value.family ) }
					families={ families }
					sort='alphabet'
				/>
				{ Object.entries( colors ).map( ( [ key, value ] ) => (
					<MuiColorInput
						key={ key }
						label={ getLabelById( key as keyof Colors ) }
						format='hex'
						value={ value }
						onChange={ ( value ) =>
							setColors( ( prevState ) => ( { ...prevState, [ key ]: value } ) )
						}
					/>
				) ) }
				<CornerSelector
					value={ corners }
					onChange={ ( e ) => setCorners( e.target.value as Corners ) }
				/>
			</Box>
			<Divider orientation='vertical' />
			<Box className={ styles.column }>
				<Typography variant='h6'>Preview</Typography>
				<Box
					sx={ {
						background: colors.fieldOutline,
						padding: '2rem',
						borderRadius,
					} }
				>
					<Box
						sx={ {
							background: '#FFFFFF',
							padding: '1rem',
							display: 'grid',
							gridTemplateColumns: '1fr',
							borderRadius,
						} }
					>
						<Typography
							sx={ {
								color: colors.instructions,
								marginBottom: '1rem',
								fontFamily,
							} }
						>
							Instructions
						</Typography>
						<TextField
							label='label'
							InputLabelProps={ {
								sx: {
									fontFamily,
									color: colors.fieldLabel,
									[ `&.${ inputLabelClasses.shrink }` ]: {
										color: colors.fieldLabel,
									},
									[ `&:not(.${ inputLabelClasses.shrink })` ]: {
										color: colors.fieldLabel,
									},
								},
							} }
							InputProps={ {
								sx: { color: colors.fieldInputText, fontFamily, borderRadius },
							} }
							sx={ {
								fontFamily,
								[ '& .MuiOutlinedInput-notchedOutline span' ]: {
									color: colors.fieldInputText,
								},
								[ '& .MuiOutlinedInput-notchedOutline' ]: {
									borderColor: colors.fieldOutline,
								},
								[ '&:focus-within .MuiOutlinedInput-notchedOutline' ]: {
									borderColor: colors.fieldOutline,
								},
								[ `&:not(.${ inputBaseClasses.disabled }):hover:not(:focus-within) .MuiOutlinedInput-notchedOutline` ]:
									{
										borderColor: colors.fieldOutline,
									},
								[ `&:not(.${ inputBaseClasses.disabled }):hover .MuiOutlinedInput-notchedOutline` ]:
									{
										borderColor: colors.fieldOutline,
									},
							} }
						/>
						<Button
							sx={ {
								marginTop: '1rem',
								background: colors.buttonFill,
								color: colors.buttonText,
								fontFamily,
								[ '&:hover' ]: {
									background: colors.buttonFill,
								},
								borderRadius,
							} }
						>
							Button
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
