/* eslint-disable @typescript-eslint/indent */
// Borrowed from https://stackoverflow.com/questions/55539387/deep-omit-with-typescript#answer-64176221
/** Union of primitives to skip with deep omit utilities. */
type Primitive =
	| string /* eslint-disable-next-line @typescript-eslint/ban-types */
	| Function
	| number
	| boolean
	| symbol
	| undefined
	| null
	| Date
	| RegExp;

/** Deeply omit members of an array of interface or array of type. */
export type DeepOmitArray<T extends any[], K = '__typename'> = {
	[P in keyof T]: DeepOmit<T[P], K>;
};

/** Deeply omit members of an interface or type. */
export type DeepOmit<T, K = '__typename'> = T extends Primitive
	? T
	: {
			[P in Exclude<keyof T, K>]: T[P] extends infer TP //extra level of indirection needed to trigger homomorphic behavior // distribute over unions
				? TP extends Primitive
					? TP // leave primitives and functions alone
					: TP extends any[]
					? DeepOmitArray<TP, K> // Array special handling
					: DeepOmit<TP, K>
				: never;
	  };

/** Deeply omit members of an array of interface or array of type, making all members optional. */
export type PartialDeepOmitArray<T extends any[], K> = Partial<{
	[P in Partial<keyof T>]: Partial<PartialDeepOmit<T[P], K>>;
}>;

/** Deeply omit members of an interface or type, making all members optional. */
export type PartialDeepOmit<T, K> = T extends Primitive
	? T
	: Partial<{
			[P in Exclude<keyof T, K>]: T[P] extends infer TP //extra level of indirection needed to trigger homomorphic behavior // distribute over unions
				? TP extends Primitive
					? TP // leave primitives and functions alone
					: TP extends any[]
					? PartialDeepOmitArray<TP, K> // Array special handling
					: Partial<PartialDeepOmit<TP, K>>
				: never;
	  }>;

/* This can help strip __typename from an object, but it doesn't always convert all types properly.
For example if you have
{ signatures: { __typename?: 'SignatureForGetContractWhere', signingDate?: string | Date | null }[] }
that will get converted to something like this
{ signatures: { signingDate: {[symbol]: { a bunch of nonsense here}} | string | null | null }[] }
*/
export const filterTypenameFromArray = <T extends any[]>(
	array: T
): DeepOmitArray<T, '__typename'> =>
		array?.reduce(
			( acc, value ) => [
				...acc,
				Array.isArray( value )
					? filterTypenameFromArray( value )
					: typeof value === 'object' && value !== null
						? // eslint-disable-next-line @typescript-eslint/no-use-before-define
				  filterTypenameFromObject( value )
						: value,
			],
			[]
		);
export const filterTypenameFromObject = <T>(
	object?: T
): DeepOmit<T, '__typename'> | undefined =>
		object === undefined
			? ( object as undefined ) // had to jump through some hoops to get cypress' tsconfig to compile this
			: ( Object.entries( object ).reduce(
				( acc, [ key, value ] ) =>
					key !== '__typename'
						? {
							...acc,
							[ key ]: Array.isArray( value )
								? filterTypenameFromArray( value )
								: typeof value === 'object' &&
									  value !== null &&
									  !( value instanceof Date )
									? filterTypenameFromObject<object>( value )
									: ( value as Primitive ),
						  }
						: acc,
				{}
		  ) as unknown as DeepOmit<T, '__typename'> );

/* A utility type to help you more easily get the type of a nested prop, even across arrays. For example
if you wanted the type of the contact inside of a signature on a contract you could use
type SomeContactType = PropertyType<ContractFragment, 'signatures.customerSignature.contact'>
I borrowed most of this from https://levelup.gitconnected.com/typescript-recursive-type-aliases-explained-211a3d196f35
*/
export type PropertyTypeArray<
	T,
	Path extends string
> = T extends readonly ( infer P )[] ? PropertyType<P, Path> : never;

export type PropertyType<T, Path extends string> = T extends any[]
	? PropertyTypeArray<T, Path>
	: Path extends keyof T
	? T[Path]
	: Path extends `${ infer K }.${ infer R }`
	? K extends keyof T
		? PropertyType<T[K], R>
		: never
	: never;

export type ArrayElement<T> = T extends readonly ( infer ElementType )[]
	? ElementType
	: never;

/** Another utility type (or types) to recursively make all properties of anything (except primitives) optional */
export type RecursivePartialArray<T extends any[]> = {
	[P in keyof T]: RecursivePartial<T[P]>;
};
export type RecursivePartial<T> = T extends Primitive
	? T
	: {
			[P in keyof T]?: T[P] extends infer TP //extra level of indirection needed to trigger homomorphic behavior // distribute over unions
				? TP extends Primitive
					? TP // Like above, leave primitives and functions alone
					: TP extends any[]
					? RecursivePartialArray<TP>
					: RecursivePartial<TP>
				: never;
	  };

/** yet another utility type (or types) to recursively make all properties of anything (except primitives) optional, not not null */
export type RecursivePartialNonNullArray<T extends any[]> = {
	[P in keyof T]: RecursivePartialNonNull<T[P]>;
};
export type RecursivePartialNonNull<T> = T extends Primitive
	? T
	: {
			[P in keyof T]?: T[P] extends infer TP //extra level of indirection needed to trigger homomorphic behavior // distribute over unions
				? TP extends Primitive
					? NonNullable<TP>
					: TP extends any[]
					? RecursivePartialNonNullArray<TP>
					: NonNullable<RecursivePartialNonNull<TP>>
				: never;
	  };

/* because accessing enums in reverse, by value, is a pain, here's a helper!
There's a bit of casting to get around javascript treating enums as objects but
typescript not doing the same, but such is life... */
export const getEnumKeyByValue = <T extends Record<string, string | number>>(
	enumObject: T,
	enumValue: string
) => {
	const index = Object.values( enumObject ).indexOf( enumValue );
	if ( index === -1 )
		throw new Error( `Enum value ${ enumValue } not found in enum ${ enumObject }` );
	return Object.keys( enumObject )[ index ];
};

/* For usage where you've got like
`string | undefined | null`
or
`{ something: { anotherThing: number | null | undefined, aThirdThing: [ { fourthThing: Date | null | undefined } ] } }`
and you want that same shape except without null or undefined values */
export type OnlyDefinedArray<T extends any[]> = {
	[P in keyof T]: OnlyDefined<T[P]>;
};
export type OnlyDefined<T> = T extends Primitive
	? NonNullable<T>
	: {
			[P in keyof T]-?: T[P] extends infer TP //extra level of indirection needed to trigger homomorphic behavior // distribute over unions
				? TP extends Primitive
					? NonNullable<TP> // Like above, leave primitives and functions alone
					: TP extends any[]
					? OnlyDefinedArray<TP>
					: OnlyDefined<TP>
				: never;
	  };

/* This is to first create a type that is your source type except without the __typename
defined in it, and then pass _that_ type into the above type where every prop/value is
required. One use-case is where you have a form of <input/>s and you want to pass those
values to the GraphQL layer where every value is required, but your source type has those
fields as option - e.g. Organization (many optional values) vs OrganizationCustomCreateInput
(mostly required values). So your source type can be `Organization`, which is returned by
many resolvers and you can transform that into `OrganizationCustomCreateInput` using this
utility type. */
export type NonNullNoTypename<T> = OnlyDefined<DeepOmit<T, '__typename'>>;

export const notEmpty = <T>( value: T | null | undefined ): value is T => !!value;
