import React, { ReactNode } from 'react';
import Header from '../../../blocks/Page/header';
import styles from './StickyHeaderLayout.module.scss';

export default function StickyHeaderLayout( {
	children,
	isActiveMobileMenu,
	toggleMobileMenu,
}: {
	children: ReactNode;
	isActiveMobileMenu: boolean;
	toggleMobileMenu: () => void;
} ) {
	return (
		<div className={ styles.root }>
			<Header
				isActiveMobileMenu={ isActiveMobileMenu }
				toggleMobileMenu={ toggleMobileMenu }
			/>
			{ children }
		</div>
	);
}
